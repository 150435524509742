#revenue_planning{
    .ant-select-arrow{
        color: #0F61DB;
    }
    .ant-input-number-input,.ant-select-selector{
        font-weight:bold;
        margin-left: 4px;
        
    }
}
.revenue-table{


.ant-form-item-control-input {
  font-size: 18px;
}

.ant-input-number-lg input {
    height: 40px;
}

.ant-table-container table > thead > tr:first-child th:first-child {
    background-color: #F9FAFB!important;
    border: none !important;
    border-bottom: 1px solid #D0D5DD !important;
}
.ant-table-container table > thead > tr:nth-child(1) th:nth-child(2),.ant-table-container table > thead > tr:nth-child(1) th:nth-child(3),.ant-table-container table > thead > tr:nth-child(1) th:nth-child(4){
    background-color: #F9FAFB !important;
    border: none !important;
    border-bottom: 1px solid #D0D5DD !important;
}
.ant-table-container{
    border: none !important;
}
}
@media print {
    .non-print {
      display: none;
    }
    .revenue-table{
        page-break-after: always !important;
    }
    .h-55{
        height: 55vh;

    }
    .h-155{
        min-height: 155vh;

    }
    .h-80{
        min-height: 80vh;
    }
    .h-70{
        min-height: 68vh;
    }
    .mt{
        margin-top: 5rem
    }
    
  }

.delete-revenue-model .ant-modal-footer{
    padding: 0px;
    width: 0px;
  }

.take-a-tour-btn{
    width: min-content;
    margin-top: 10px;
}
.baselineMetricsTable tr td:last-child{
    text-align: center !important;
}
.ant-checkbox-inner{
height: 20px !important;
width: 20px !important;
}

.drag-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    color: rgba(0, 0, 0, 0.85);
    border: none;
    padding: 5px;
    cursor: move;
}

.sortable-list-tab {
    cursor: default;
    visibility: visible;
    z-index: 99999999;
  }
.table-cell-style th.ant-table-cell{
    background: white !important;
    border: none !important;
}
.market-drawer .ant-drawer-body{
    padding: 0px !important;
   }
   .pannel-arrow{
       .ant-select-arrow{
         stroke: #000;
         stroke-width: 50;
       }
     }
.collapse-style{
       .ant-collapse-item:nth-child(2), .ant-collapse-item:nth-child(3), .ant-collapse-item:nth-child(4){
         margin-top: 15px;
       }
         .ant-collapse-arrow{
           stroke: #000;
           stroke-width: 50;
       }
       .ant-collapse-content-box{
         padding-top: 0px !important;
         padding-bottom: 0px !important;
       }
      .ant-collapse-header{
          background-color: #F3F3F3;
          font-size: 18px;
          font-weight: 600;
      }
      .ant-collapse-content-box{
          font-size: 18px;
          line-height: 40px;
      }
      .ant-select-selection-placeholder{
          padding-top: 0px !important;
      }
     }
.market-select .ant-select-selection-placeholder{
       padding-top: 0px !important;
   }
.header-style{
       font-weight: 600;
   }
.single-segment-message{
    font-size: 15px;
}
.quarter-table-header{
    background: #E9F2FF;
    height: 50px;
    display: flex;
    align-items: center;
}
.quarter-table-header1{
    background: #F8FBFF;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.quarter-table-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    margin-left: 15px;
}
.expand-collapse-icon-style{
    margin-top: 2.5005px;
    margin-right: 15px;
    cursor: pointer;
    float: right;
    padding-top: 6px;
    display: block;
}

@primary-color: #0f61db;@menu-dark-bg: #030D1D;@menu-light-bg: #e0e0e0;@layout-sider-background: #e0e0e0;@menu-dark-item-active-bg: #030D1D;@menu-highlight-color: #1CD9F2;@menu-dark-selected-item-text-color: #1CD9F2;@menu-dark-selected-item-icon-color: #1CD9F2;@menu-dark-item-hover-bg: #030D1D;@menu-light-item-hover-bg: #e0e0e0;@font-family: Gilmer;