@import "src/App.less";
.mapping-selector-dropdown{
    padding: 20px;
}
.mapping-option{
    margin: 5px 0px;
}

.ant-select-item-group{
    color: black !important;
    background: #F3F3F3 !important;
    font-size: 15px !important;
    font-weight: bold !important;
    padding: 5px 23px !important;
}
.Create-mapping-stage{
    .ant-select-selector{
        height: auto !important;
        min-height: 44px !important;
    }
}

.map-title {
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 28px;
letter-spacing: 0em;
text-align: center;
padding-top: 10px;
padding-bottom: 30px;
}

.ant-table-thead > tr > th  {
    background: #f5f7f9 !important;
}

.mapping{
    .ant-table-container{
        border-top: none !important;
      }
      .ant-table-thead .ant-table-cell{
        border-radius: 7px 7px 0 0 ;
      }
    .mapping-transfer-image{
        background: rgb(255, 255, 255) !important;
    }
}
.crm-stages-cell{
    border-top-right-radius: 0 !important;
}
.mapping-transfer-edit{
    border-radius: 0 !important;
}


.reqList .ant-list-empty-text{
    height: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.thankyou-page{
    position: fixed;
    z-index: 1111;
    background-color: #fff;
    color: red;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url("/assets/Images/plan-selected-success-bg.svg");
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .thankyou-modal{
    background-color: #E0FBFF;
    width: 512px;
    margin-left: calc(50% - 256px);
    border-radius: 20px;
    padding: 20px 0px;
  }

  .integrationTab .ant-tabs-tab{
    font-size: 16px;

  }

  .integrationTab {
    .ant-tabs-extra-content{
        flex: none !important;
    }
  }
  .crm-config-drawer .section .radio-text{
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #303030;
    width: 100%;
    position: relative;
  }
  .crm-config-drawer .section .radio-text span:nth-child(2) span{
    position: absolute;
    right: 0;
  }
  .crm-config-drawer .section-title{
    background: #F3F3F3;
    padding: 12px 24px;
    font-size: 18px;
    line-height: 20px;
    color: #303030;
    font-weight: 700;
    height: 44px;
    margin-left: -24px;
    margin-right: -24px;
    min-width: 100%;
  }
  .crm-config-drawer .ant-collapse-header{
    background: #F3F3F3;
    font-size: 18px;
    line-height: 20px;
    color: #303030;
    font-weight: 700;
    padding-left: 24px !important;
  }
  .crm-config-drawer .ant-collapse-content-box{
    background-color: white;
    padding-left: 24px;
    padding-right: 24px;
  }
  .crm-config-drawer .ant-drawer-body{
      padding-left: 0;
      padding-right: 0;
  }
  .crm-config-drawer .object-name{
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #303030;
    margin-bottom: 10px;
  }
  .crm-config-drawer .no-object-name{
   padding-top: 10px;
   padding-bottom: 5px;
  }
  .ant-drawer-header-title{
      flex-direction: row-reverse;
  }
  .ant-drawer-header-title .ant-drawer-close{
    margin-left: 12px;
    margin-right: 0;
  }
.tabs-wrapper .ant-tabs-tab{
    background: #E9F2FF !important;
    font-weight: 700 !important;
    font-size: 18px;
}
.tabs-wrapper .ant-tabs-tab:nth-child(2){
    margin: 0 !important;
}
.tabs-wrapper .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #0F61DB !important;
}
.tabs-wrapper .ant-tabs-ink-bar {
  width: 3px !important;
}

.tabs-wrapper .ant-tabs-tab:nth-child(2){
  margin: 0 !important;
  border-bottom-left-radius: 8px !important ;
  border-bottom-right-radius: 10px !important ;
}
.tabs-wrapper .ant-tabs-tab:nth-child(1){
  margin: 0 !important;
  border-top-left-radius: 8px !important ;
  border-top-right-radius: 10px !important ;
}
.campaign-p{
  margin-bottom: 0px !important;
  color: gray;
}
.pannel-arrow{
  .ant-select-arrow{
    stroke: #000;
    stroke-width: 50;
  }
}
.tooltip-arrow{
  .ant-tooltip-arrow{
    display: contents;
}
}
.heading-text{
  margin-top: 10px;
  font-style: italic;
  color: @themecolor1;
  line-height: 24px;
}
.collapse-style{
  .ant-collapse-item:nth-child(2), .ant-collapse-item:nth-child(3), .ant-collapse-item:nth-child(4),.ant-collapse-item:nth-child(5){
    margin-top: 15px;
  }
    .ant-collapse-arrow{
      stroke: #000;
      stroke-width: 50;
  }
  .ant-collapse-content-box{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

}

.confirm-save-model{
  width: 350px !important;
  .ant-modal-header{
  padding-bottom: 5px;
  border-radius: 50px;
  border-bottom: none !important;
  .ant-modal-title {
    font-size: 16px;
    font-weight: bold !important
  }}
  .ant-modal-body {
    justify-content: center;
    text-align: center;
    padding-left: 24px !important;
    padding-top: 4px;
    padding-bottom: 4px;

  }
  .ant-modal-footer{
    border-top: none !important;
    text-align: center;
    padding-bottom: 1rem;
  }
}

.cancel-btn {
  border: 1px solid @themecolor4;
  box-sizing: border-box;
  border-radius: 7px;
  color: @themecolor4;
  font-weight: 500;
  height: 40px;
  width: 150px;
}
.save-btn {
  border: 1px solid @themecolor4;
  box-sizing: border-box;
  border-radius: 7px;
  font-weight: 500;
  width: 150px;
}

.mapping-stages-dot:after{
  content: '';
  justify-content: end !important;
  display: block;
  position: relative;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  // margin-left: 150px;
  top: 0;
}
.show-stage-chnages-text::after{
  background-color: #0F61DB
}

.tooltip-wrapper{
  display: flex;
  align-items:center;
  gap: 0.7rem;
}

.loder-3quarter{
  margin-top: 7px;
  margin-left: 20px;
  position: absolute;
  font-size: 18px;
}
.admin-tag {
  margin-left: 6px;
  border-radius: 5px;
  border:  1px #0F61DB solid;
}
@primary-color: #0f61db;@menu-dark-bg: #030D1D;@menu-light-bg: #e0e0e0;@layout-sider-background: #e0e0e0;@menu-dark-item-active-bg: #030D1D;@menu-highlight-color: #1CD9F2;@menu-dark-selected-item-text-color: #1CD9F2;@menu-dark-selected-item-icon-color: #1CD9F2;@menu-dark-item-hover-bg: #030D1D;@menu-light-item-hover-bg: #e0e0e0;@font-family: Gilmer;