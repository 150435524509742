.fields{
    margin-top: 40px;
}
.fields .title{
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}
.fields .title h5{
    margin-bottom: 0;
    font-weight: 600;
}
.fields .title span{
    color: #0F61DB;
}
.table{
    margin-bottom: 20px;
}
.table p, .table h4{
    margin-bottom: 0;
}
.table .title{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border: 1px solid #D0D5DD;
    background-color: #f5f7fa;
    
}
.table .title span{
    color:#1D2939;
    font-weight:500
}
.table .data{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border: 1px solid #D0D5DD;
    border-left: none;
    text-align: center;
    font-weight:700; 
    font-size:18px;
}
.table .data h4{
    color: #7F56D9;
    font-weight: 600;
}
.matricTable {
    margin-top: 30px;
}
.matricTable h4{
    background-color: #f5f7fa;
    padding: 10px;
    border-radius: 7px;
    font-weight: 600;
    height: 70px;
    display: flex;
    align-items: center;
}
.matricTable .ant-table table{
    font-size: 15px !important;
}
.matricTable .ant-table .ant-table-thead th{
    padding: 11px 10px !important;
    background-color: #f5f7fa !important;
    border: none !important;
    font-weight: 600;
    height: 70px ;
}
.matricTable input{
    /* margin: 7px 0; */
    height: 40px;
}
.ant-divider-horizontal {
    border-top: 1px #D0D5DD solid !important;
}
.ant-popover-inner-content .ant-radio-wrapper{
    display: flex !important;
    align-items: center !important;
}
.ant-table-cell.sdr-table-head{
    padding: 0 !important;
    background: white !important;
}
.sdr-table-head h4{
    padding: 8px !important;
}
.sdr-table .ant-table-thead tr th:nth-child(4){
    border-radius: 7px 7px 0 0  !important;
}
.sdr-table .ant-table-thead > tr > th{
    background-color: #F6FAFF !important;
    border: none !important;
}
.sdr-table tr:last-child th:first-child{
    border-radius: 0;
}
.sdr-table .text_label.data{
    font-size: 13px;
    padding-bottom: 0;
}
.sdr-table .background{
    background: #F6FAFF;
}
.sdr-table .background span.data{
    color: #00AEC5;
}
.sdr-table .ant-table-bordered > .ant-table-container{
    border: none !important;
}
.sdr-table .footer{
    background-color: #F9F9F9 !important;
}
.sdr-table .footer .text_label{
    font-size: 13.5px;
    text-align:left;
    color: #E13A3A;
}
.sdr-table .text_label{
    font-weight: 600;
}
.variableInput .ant-input-number-input{
    text-align: center;
    font-weight: 600;
}
.hubspot-select .ant-select-selection-item, .compensetion-select .ant-select-selection-item {
    margin: auto;
}
.sdrCompensation .anticon-dollar-circle{
    top: 22px !important;
}