@import "ThemeVariables.less";

.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 16px 10px;
}
.avatar-uploader > .ant-upload {
  width: 100%;
  height: 110px;
}

.ant-upload {
  overflow: hidden;
}

.course-list-admin .ant-pagination-total-text {
  width: 100%;
}

.course-list-admin .ant-pagination-item-active {
  border-color: @themecolor1 !important;
}

.course-list-admin .ant-pagination-item-active a {
  color: @themecolor1 !important;
}

.course-admin-tab .ant-tabs-tab-btn {
  font-size: 18px;
}

.ant-table-thead .admin-table-header {
  font-weight: 800;
}

.course-admin-tab .ant-tabs-content-holder {
  padding-top: 10px;
}

.course-admin-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: @themecolor5 !important;
}
.course-admin-tab .ant-tabs-tab-btn {
  color: @themecolor6 !important;
}

.course-list-admin .ant-tag-green {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: @themecolor1 !important;
  background: #fff;
  border-color: @themecolor1;
  padding: 7px;
  border-radius: 0px;
  margin-top: 2px;
}

.course-list-admin .ant-table-cell-ellipsis {
  // height: 100px;
  // display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: @themecolor5;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  // padding-top: 10px;
  white-space: break-spaces;
}

.thumbnail-section img {
  width: 100%;
  height: 135px;
}

.company-name {
  cursor: pointer;
  color: @themecolor1;
}

.assign-text {
  color: @themecolor3;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: right;
  cursor: pointer;
}

.create-package .ant-select-selector {
  height: auto !important;
}

.edit-package {
  padding-bottom: 0px;
  padding-right: 30px;
  color: blue;
  cursor: pointer;
}
.adminTable .ant-pagination-options{
  display: none !important;
}
.nextbtn{
  display: block;
  width: 24px;
  height: 24px;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
  margin: 0px 7px;
}
.add-company-drawer .ant-select-selection-placeholder{
  line-height: 28px !important;
}
@primary-color: #0f61db;@menu-dark-bg: #030D1D;@menu-light-bg: #e0e0e0;@layout-sider-background: #e0e0e0;@menu-dark-item-active-bg: #030D1D;@menu-highlight-color: #1CD9F2;@menu-dark-selected-item-text-color: #1CD9F2;@menu-dark-selected-item-icon-color: #1CD9F2;@menu-dark-item-hover-bg: #030D1D;@menu-light-item-hover-bg: #e0e0e0;@font-family: Gilmer;