.Checked li{
    background-image: url('../../assets/Images/Checked.svg');
    background-position: 0 6px;
    background-repeat: no-repeat;
    padding-left: 25px;
    font-size: 14px;
    margin-bottom: 10px;
}
.Checked{
    margin: 0;
}
.brand-detail-form .colors .ant-input-affix-wrapper{
    padding-left: 0 !important;
    height: 32px !important;
}
.brand-detail-form .colors .ant-input-prefix div{
    width: 30px !important;
    height: 30px !important;
    border-radius: 5px;
}

.empty-color {
    border: 1px solid;
    border-style: dashed;
    color: #808080;
}
.pen-style > span.anticon{
   cursor: pointer !important;
}
.custom-button-style {
    border: 1px solid;
    height: 40px;
    width: 87px;
    border-radius: 7px;
}

.custom-info-icon {
    margin-top: 3px;
}
.salesforce_connect_modal .ant-modal-header{
    border-bottom: 1px solid #D0D5DD;
    padding: 22px 20px;
    background: #f5f7fa;
}
.salesforce_connect_modal .ant-modal-content{border-radius: 0;}
.salesforce_connect_modal .ant-modal-header .ant-modal-title{text-align: left;}
.salesforce_connect_modal .ant-modal-body{padding:0;}
.salesforce_connect_modal .ant-modal-body .actions{
    padding:30px 20px
}
.salesforce_connect_modal .ant-radio-group{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.salesforce_connect_modal .ant-radio-group label.ant-radio-wrapper{
    font-size: 18px;
    align-items: center;
}
.salesforce_connect_modal .ant-radio-group label.ant-radio-wrapper span.ant-radio{
    margin-bottom: 8px;
}
.salesforce_connect_modal .ant-radio-group label.ant-radio-wrapper .ant-radio .ant-radio-inner{
    width:25px;
    height:25px
}
.salesforce_connect_modal .ant-modal-body .footer_actions{
    padding:15px 20px
}