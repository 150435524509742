@import "ThemeVariables.less";
// Color variables (appears count calculates by raw css)
@color0: #fbfbfb;
@color1: #808080;

.ant-layout {
  background: #f8fafb;
}

.primary-color {
  color: @primary-color;
}

.coaching-card {
  background-color: #fff;
  padding-left: 12px;
  // border-radius: 5px;
  // border: 1px solid rgb(235, 237, 240);
}

.card-text {
  color: @color1;
  font-size: 12px;
  text-align: center;
}

.ant-card-body {
  padding: 10px;
}

.coaching-video .ant-modal-body {
  padding: 30px 0 0 0;
}

.ant-modal-close-x {
  font-size: 10px;
  line-height: 28px;
}

.ant-modal-close {
  top: 2px;
  right: -8px;
}

.player-wrapper {
  position: relative;
  height: 100%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.coaching-video .ant-modal-content {
  height: 400px;
}

.card-description {
  height: 100px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.attachment-url {
  display: inline-block;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
}

.course-details-title {
  //   padding-top: 20px;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: @themecolor5;
}

.course-details-desc {
  padding-top: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: @themecolor5;
}

.duration-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 6px;
  color: @themecolor6;
  vertical-align: middle;
}

.title-course-video {
  padding-left: 20px;
  padding-top: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: @themecolor5;
}

.course-video-devider {
  border-bottom: 2px solid #f1f1f1;
  // padding-top: 20px;
  // padding-bottom: 20px;
}

.course-material {
  //margin: 20px !important;
  padding-bottom: 0px;
  padding-top: 0px;
  //padding-left: 10px;
  margin-bottom: 10px;
  //border: 1px solid #e8e8e8;
  border-radius: 7px;
}

.material-download {
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: @themecolor1;
  cursor: pointer;
}

.material-file-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  //width: 10%;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0em;
  //text-align: left;
  color: @themecolor5;
}

.course-description-section {
  background: @themecolor3;
  padding: 24px;
  color: #fff;
}

.course-banner {
  position: relative;
  text-align: center;
  color: white;
}

.course-div-titles{
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 0%;
  left: 5%;
  height: 100%;
  justify-content: center;
}

.course-category-banner {
  //position: absolute;
  //top: 45%;
  //left: 5%;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
  letter-spacing: 0em;
  color: #00AEC5;
  background: #ffffff;
 // text-shadow: 2px 2px #727171;
  border-radius: 7px;
  padding: 4px;
  padding-right: 20px;
  padding-left: 20px;
}

.course-title-banner {
  //position: absolute;
  //top: 50%;
  //left: 5%;
  //text-shadow: 2px 2px #727171;
  font-size: 34px;
  font-style: bold;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  width: 60%;
  padding-top: 5px;
}

.coaching-header-section {
  background: #fff;
  padding-left: 30px;
  border-radius: 7px;
}

.coaching-sub-header {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: @themecolor7;
  padding-top: 34px;
}

.coaching-header-description {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: @themecolor5;
  padding-top: 16px;
  padding-bottom: 40px;
}

.coaching-header-img {
  padding-left: 25px;
  padding-top: 26px;
}

.coaching-tab .ant-tabs-tab-btn {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #727272;
  text-transform: lowercase;
}
.coaching-tab .ant-tabs-tab-btn::first-letter{
  text-transform: uppercase;
}

.coaching-tab .ant-tabs-nav-wrap {
  background-color: #fff;
  // border-radius: 7px;
  border-bottom: 1px solid #f1f1f1;
  padding-left: 20px;
}

.coaching-tab.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 !important;
}

.coaching-tabs {
  background-color: #fff;
}

.coaching-tab {
  border-radius: 7px;
}

.courses-tabs {
  background-color: #fff;
}

.course-title {
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: @themecolor5;
  cursor: pointer;
  margin-top: 12px;
}

.course-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: @themecolor6;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.course-description-title {
font-size: 20px;
font-style: normal;
font-weight: bold;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
}
/*
@media screen {
  @media (max-width: 750px) {

    .course-category-banner{
      font-size: large;
      position: static;
      width: 20%;
      margin-top: 5%;
      margin-left: 6%;
    }

    .course-title-banner{
      font-size: 30px;
      position: static;
      width: 50%;
      margin-top: -3%;
      margin-left: 6%;
      margin-bottom: 5%;
    }
  }
}*/

.coaching-title-link{
  font-family: Gilmer;
  font-size: "16px";
  font-weight: 500;
  transition: 0.5s ease-in-out;
}

.coaching-desc{
  font-family: Gilmer;
  font-size: "16px";
  font-weight: 400;
}

.coaching-title-link:hover{
  //font-size: 16px;
  color: var(--primaryColor);
  // text-transform: capitalize;
  // animation-name: example;
  // animation-duration: 4s;
 }

// @keyframes example {
//   from {color: #A16AE8;}
//   to {color: #5C7AEA;}
// }

.similar-blog-image:hover{
  //width: 90%;
  transform:  scale(1.08, 1.08);
  transition-duration: 1s;
}

// .ok-course img{
//   object-fit: cover;
// }

// AmplifyS3Image, amplify-s3-image{
//   object-fit: fill;
//   height: "100%";
//   border: 20px solid #f00;
// }


.courses-tabs .ant-skeleton-image {
  padding: 20px;
    width: 300px;
    height: 230px;
    margin-right: 10px;
}
@primary-color: #0f61db;@menu-dark-bg: #030D1D;@menu-light-bg: #e0e0e0;@layout-sider-background: #e0e0e0;@menu-dark-item-active-bg: #030D1D;@menu-highlight-color: #1CD9F2;@menu-dark-selected-item-text-color: #1CD9F2;@menu-dark-selected-item-icon-color: #1CD9F2;@menu-dark-item-hover-bg: #030D1D;@menu-light-item-hover-bg: #e0e0e0;@font-family: Gilmer;