#defaultStagesTable table {
    border-top: none;
    border-spacing: 16px 0;
    margin-left: -16px;
}

#defaultStagesTable thead th {
    border: none;
}

#defaultStagesTable th:last-child {
    background: #fff !important;
    border-radius: 0 !important;
}

#defaultStagesTable td:last-child {
    border: none !important;
}

#defaultStagesTable thead th:nth-child(1){
    background: #E9F2FF !important;
}

#defaultStagesTable thead th:nth-child(3),
#defaultStagesTable thead th:nth-child(4),
#defaultStagesTable thead th:nth-child(5) {
    background: #e0e0e0 !important;
    color: #fff !important;
}

#defaultStagesTable thead th:nth-child(6){
    border-radius: 7px 0 0 0 !important;
    background: #E9F2FF !important;
}

#defaultStagesTable td:nth-child(2) {
    border-right: none;
}

#defaultStagesTable td:not(:nth-child(2)) {
    border-left: 1px solid #f0f0f0;
}

#defaultStagesTable th:nth-last-child(2)::after {
    border-right: none !important;
}

#defaultStagesTable th:last-child::after {
    border-right: none !important;
}

#defaultStagesTable .italic-cell span.three-text-rows {
    /* max-height: 60px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3;
    -webkit-box-orient: vertical;

}

.site-drawer-render-in-current-wrapper {
    position: relative;
    width: 100%;
    /* height: fit-content; */
    /* padding: 48px; */
    overflow: hidden;
    text-align: center;
    /* background: #fafafa; */
    /* border: 1px solid #ebedf0; */
    /* border-radius: 2px; */
}
/* .site-drawer-render-in-current-wrapper .ant-drawer-body{
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
} */

.edit-mapping-stage .ant-form-item-label label{
    font-weight: 600;
}
.edit-mapping-stage .ant-drawer-header-title{
    flex-direction: row-reverse;
}

/* .table-wrapper{
    width: 100%;
    position: relative;
    border: 1px solid #E2EBF3;
} */
.table-wrapper .ant-table-tbody > tr > td {
    border: 1px solid #E2EBF3 !important;
}
.table-wrapper .scrollBox{
    display: -webkit-inline-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
/* .table-wrapper .flexBox{
    display: flex;
} */
.table-wrapper .column{
    min-width: 330px;
    width: 32%;
    /* max-width: 550px !important;
    min-width: 170px !important; */
    /* border-radius: 7px;  */
    border: 1px solid #f0f0f0;
    display: inline-block;
}
.table-wrapper .header{
    padding: 10px;
    border-radius: 7px 7px 0 0;
}
.table-wrapper .arrows{
    width: 80px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding: 0 30px;
}
.table-wrapper .mr-10{
    /* margin-right: 20px; */
}
.table-wrapper .fixed{
    position: sticky;
    right: 0;
    top: 0;
    background-color: white;
    height: inherit;
    width: 70px;
    box-shadow: 2px 4px 9px rgb(0 0 0 / 5%);
}
.table-wrapper .data{
    /* min-height: 90px; */
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
    height: 90px;
}
.table-wrapper .arrows .data{
    border: none;
}
.table-wrapper p{
    margin-bottom: 0 !important;
}
.table-wrapper .defaultStages .data{
    font-size: 18px;
}

.table-wrapper .CRMMapping{
    position: absolute;
    right: 70px;
    top: 0;
    width: 299px;
    z-index: 1200;
    background-color: #fff;
    padding:0px  15px;
    box-shadow:  -9px -1px 13px -5px rgb(0 0 0 / 8%);
    display: block;

}
.table-wrapper .CRMMapping .ant-drawer-body{
    padding: 0 !important;
}
.CRMMappingClosed{
    display: none;
    position: absolute;
    right: 70px;
    top: 0;
    visibility: hidden;
    background-color: #fff;
}
.mapping-drawer{
    transition: .2s;
}
.CRMMapping .ant-select-multiple ::-webkit-scrollbar{
    display: none;
}

.sales-and-marketing-stages-dot:after{
    content: '';
    display: block;
    position: relative;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    top: 0;
}
.last-stage-text::after{
    background-color: #0F61DB;
}
.show-pipeline-text::after{
    background-color: #ff7a59;
}
.hint-title{
    display: block;
    color: #000;
    position: relative;
    padding: 2px 5px;
}
.hint-title::before{
    content: "";
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    left: -10px;
    top: calc(50% - 5px);
}

.hint-title-hubspot::before{
    background-color: #ff7a59;

}
.hint-title-salesforce::before{
    background-color: #179cd7;
}
.hint-title-last-stage::before{
    background-color: #0F61DB;
}
.hint-title-show-pipeline:before{
    background-color: #ff7a59;

}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: normal !important;
}
.default-option:hover {
    background-color: rgb(230, 242, 255) !important;
}



.table_col_hide .ant-table-thead > tr > th {
  padding: 0px !important;
  display: none !important;
}
.table_col_hide .ant-table-tbody > tr > td {
  padding: 12px 12px!important;
}
.table_col_hide {
  max-width: 100%;
  overflow-x: auto;
  scrollbar-color: blue;
  scrollbar-width: thin;
}
.table-wrapper-class{
    width: 100%;
    position: relative;
    border: 1px solid #E2EBF3;
}
.funnel-stages {
    background: #F5F5F5;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.funnel-stages-header {
    font-family: Gilmer;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 134.19%;
    text-transform: capitalize;
    color: #0F61DB;
    margin-left: 20px;
}
.title-class-header{
    font-size: 22px;
    font-weight:400 ;
}
.table-header-div {
    background: #E9F2FF;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.createNewStage {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 9px 20px;
    gap: 10px;
    width: 114px;
    height: 37px;
    background: #0F61DB;
    border-radius: 7px;
}
.table-stages-title {
    font-family: Gilmer;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-transform: capitalize;
    margin-left: 20px;
}
.tooltip-icon {
    width: 16px;
    height: 16px;
    color: #A9A9A9;
    margin-left: 12px;
}
.stage-column {
    align-items: center;
    padding: 12px 10px;
    gap: 8px;
    height: 66px;
    background: #FFFFFF;
    border: 1px solid #E2EBF3;
}
.stage-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.stage-column-value {
    width: 170px;
   font-family: Gilmer;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #303030;
}
.stage-specify-class {
    display: flex;
    align-items: center;
    gap: 13px
}
.stage-specify-class .stage-image-viewer {
    width: 13px;
    height: 12px;
}
.mapping-dynamic-stages-dot {
    content: '';
    display: block;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    top: 0;
    background: #F88667;;
}
.stage-column-defiantion {
    align-items: center;
    padding: 12px 10px;
    gap: 10px;
    height: 66px;
    background: #FFFFFF;
    border: 1px solid #E2EBF3;
}
.ant-table-cell-ellipsis {
    white-space: inherit !important;
}
.defiantion-data {
    margin-bottom: 0px !important;
}
.crm-dynamic-mapping-drawer {
    position: absolute;
    padding: 0px 15px;
    background: #FFFFFF;
    z-index: 1000;
    top: -41px;
    right: 30px;
    width: 299px;
    box-shadow:  -9px -1px 13px -5px rgb(0 0 0 / 8%);

}
.crm-drawer-header {
    background: #E9F2FF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;
    border-bottom: 2px solid #FFFFFF;
    border-radius: 7px 7px 0 0;
    padding: 10px
}
.crm-mapping-drawer .ant-select-selector {
    height: auto;
    max-height: 62px !important;
    transition: .2s;
}
.data-test{
    display: flex;
    align-items: center;
    border-top: 1px solid #f0f0f0;
    width: 100%;
    height: 67px;
}
.border-line {
    position: absolute;
    width: 90%;
    align-items: center;
    height: 0px;
    margin-top: 25px;
    border: 1px dashed #A9A9A9;
}
.saveStageButton {
    height: 38px;
    margin-right: 12px;
    font-size: 16px;
    padding-left: 20;
    padding-right:20;
}
.resetStageButton {
    height: 38px;
    margin-right: 12px;
    font-size: 16px;
    color: #303030;
}
.funnel-wrapper {
    margin-top: 16px;
    margin-right: 20px;
}
.stage-clarification-tooltip-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
}
.stage-clarification-text{
    text-align: center;
    font-family: initial;
    display: flex;
    font-weight: 800;
    color: #a9a9a9
}

/* Styleling for make UI responsive */
@media only screen and (min-width: 985px) and (max-width: 1320px){
    .border-line{
        width: 100%;
    }
    .title-class-header{
        font-size: 18px;
    }
}
@media (max-width: 978px) {
    .title-class-header{
        font-size: 18px;
    }
    .table-header-div{
        margin-top: 30px;
    }
    .border-line{
        width: 100%;
    }
    .funnel-wrapper{
        margin-top: 20px;
        padding: 20px;
        margin-right: 0px;
    }
}