@import "ThemeVariables.less";

.gtm .ant-tabs-ink-bar{
    background-color: var(--secondaryColor) !important;
    height: 2px !important;
}
.gtm .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: var(--primaryColor) !important;
}
.gtm .ant-tabs-tab{transition:0.5s ease-in-out}
.gtm .ant-tabs-tab .ant-tabs-tab-btn{transition:0.5s ease-in-out; color:#1D2939}
.gtm .ant-tabs-tab:hover .ant-tabs-tab-btn{
    color: var(--primaryColor) !important;
}

.targetAudience .ant-select-selector{
    padding: 6px;
}
.targetAudience .ant-select-selection-item{
    border-radius: 100px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: #7F56D9;
}
.targetAudience .ant-select-selection-item-content{
    font-size: 13px;
    color: #7F56D9;
}
.targetAudience .ant-select-selection-item-remove{
    color: #7F56D9;
}
.targetAudience .ant-collapse-header{
    background-color: var(--primaryColor) !important;
    color: white !important;
    font-weight: bold !important;
    font-size: 18px;
}
.targetAudience .ant-collapse-content{
    background-color: #F8FBFF;
}
.targetAudience .ant-collapse-content input , .targetAudience .ant-collapse-content textarea , .targetAudience .ant-collapse-content .ant-select-selector{
    background-color: transparent !important;
}
.targetAudience .ant-collapse-header .anticon{
    top: 20px !important;
}
.targetAudience .ant-collapse-item{
    margin-bottom: 20px;
    border: 0px;
}
.targetAudience .ant-collapse{
    background-color: transparent;
    border: 0;
}

.systemsProcess .ant-tag-checkable{
    background-color: #D6BBFB !important;
    border-color: transparent;
    cursor: pointer !important;
    border-radius: 100px !important;
    margin-bottom: 7px !important;
    padding: 5px 15px !important;
    font-size: 13px;
}
.systemsProcess .ant-tag-checkable:hover{
    color: black;
}
.systemsProcess .ant-tag-checkable-checked{
    background-color: #7F56D9 !important;
    color: white !important;
}
.crmSystem .ant-checkbox-inner{
    border-radius: 50% !important;
    border-color: black !important;
}
.crmSystem .ant-checkbox-wrapper{
    width: 100%;
    align-items: center;
    /* margin-top: 20px; */
}
.crmSystem .ant-checkbox-wrapper span{font-weight: 500; color:'#1D2939'}
.crmSystem .ant-checkbox-wrapper span:nth-child(2){
    padding-top:5px
}
.crmSystem .ant-checkbox:hover , .crmSystem .ant-checkbox:focus , .crmSystem .ant-checkbox-checked:focus{
    border: none !important;
    border-radius: 50% !important;
}
.crmSystem .ant-checkbox-checked .ant-checkbox-inner{
    border-color: transparent !important;
}
.assetSearch .ant-input-wrapper{
    position: relative;
}
.assetSearch .ant-input-wrapper input{
    padding-left: 35px;
}
.assetSearch .ant-input-group-addon{
    position: absolute;
    left: 0;
    z-index: 99999;
}
.assetSearch .ant-input-group-addon button{
    border-right: none;
    border-radius: 8px 0 0 8px !important;
}
.VideoWrapper{
    padding-top: 10px;
    position: relative;
}
.VideoWrapper img,.Amplify-image-wrapper,.VideoWrapper.videos img,.Amplify-image-wrapper-video{
    z-index: 9;
    border-radius: 10px;
    height: 300px;
    width:300px;
}
.Amplify-image-wrapper{
    overflow: hidden;
}
.Amplify-image-wrapper-webinar, .AssetImage-webinar{
    height: 100px !important;
    width: 130px !important;
}

.VideoWrapper .edit {
    position: absolute;
    right: 10px;
    top: 20px;
}
.VideoWrapper .edit::after{
    z-index: 999;
}
.VideoWrapper .edit button{
    padding: 0 !important;
}
.targetAudience .ant-list-item-meta-title{
    margin-bottom: 0 !important;
}
.webinarList .ant-list-item{
    align-items: unset !important;
}
.editAssetDrawer .ant-select-selector{
    align-items: center !important;
    min-height: 45px !important;
}
.editAssetDrawer .ant-upload{
    height: 300px;
    width:300px;
}
.editAssetDrawer .ant-upload .ant-upload-btn{
    padding:0;
}
.crmSystem .ant-form-item {
    margin-bottom: 0 !important;
    margin-top:5px;
}

.gtm .ant-select-selection-placeholder {
    font-size: 16px;
  }


.SearchAssets .ant-select-selection-item , .SearchAssets .ant-collapse-item .ant-select-selection-item{
    justify-content: left;
    text-transform: lowercase !important;
}
.SearchAssets  span.ant-select-selection-item::first-letter{
    color: red;
}
.createAssetModal .ant-drawer-title{
    font-weight: 600;
}

.AssetImage{
    // max-height: 250px !important;
    width: 100% !important;
    justify-self: center;
}

.pdf-img{
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: red;
    mix-blend-mode: color;
    z-index: 999;
}
.download-btn {
    background-color: transparent;
    color: rgb(15, 97, 219);
    border-color: rgb(15, 97, 219);
    font-weight: 600;
    padding: 5px;
    font-size: 12px;
    height: 30px;
    font-size: 12px;
    padding-right: 10px;
}
@primary-color: #0f61db;@menu-dark-bg: #030D1D;@menu-light-bg: #e0e0e0;@layout-sider-background: #e0e0e0;@menu-dark-item-active-bg: #030D1D;@menu-highlight-color: #1CD9F2;@menu-dark-selected-item-text-color: #1CD9F2;@menu-dark-selected-item-icon-color: #1CD9F2;@menu-dark-item-hover-bg: #030D1D;@menu-light-item-hover-bg: #e0e0e0;@font-family: Gilmer;