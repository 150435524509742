.TEST-Header {
  display: flex;
    padding: 20px 0px;
    font-size: 20px;
    font-weight: 400;
}
.year-selector{
  display: flex;
  flex-direction: column;
}

.no-data-div {
    width: 200px;
    display: flex;
    justify-content: center;
    height: 90%;
    align-items: center;
}
.selection-header-title {
  font-size: 16px;
  font-weight: 600;
  margin-left: 5px;
  margin-bottom: 8px;
}
.executive-tabs .ant-tabs-nav {
  border-radius: 7px;
  padding:  0 20px;
  background-color: #fff;
}
.executive-analysis {
  border: 1px solid #D0D5DD;
  padding:  10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.analysis-chart-wrapper {
  border: 1px solid #D0D5DD;
  border-radius: 7px;
  padding: 15px;
}
.analysis-tab-wrapper{
  background: #fff;
  margin-bottom: 15px;
  font-size: 32px;
  font-weight: bold ;
  border-radius: 7px;
}

.analysis-title {
  color: #303030;
  font-size: 14px;
  font-weight: 400;
}
.table-row-data {
 font-size: 16px;
 font-weight: 200;

}
.analysis-value {
  color: #0F61DB;
  font-size: 22px;
  padding: 5px 0px;
}
.roi-Indicator {
  color: black;
  font-size: 22px;
  padding: 5px 0px;
}
.roi-positive-Indicator {
  color:green;
  font-size: 22px;
  padding: 5px 0px;
}
.nowrap {
  white-space: nowrap;
}

.reporting-card-body{
  background: #fff;
  margin-bottom: 15px;
  padding: 20px;
  font-weight: bold ;
  border-radius: 7px;
  border: 1px solid var(--Gray-300, #D0D5DD);
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}

.table-campaign-text{
    color: grey ;
    font-weight: lighter;
    font-size: 14px;
}
.table-campaign-text:hover {
  color: black;
}

.override-fixed-column-style .ant-table-cell-fix-left {
  background-color: transparent 
}
.override-fixed-column-style .ant-table-tbody{
    cursor: pointer;
}

.header-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.custom-table .ant-table-thead th {
  font-size: 16px;
}

.custom-table .ant-table-tbody td {
  font-size: 14px;
}

.custom-table .ant-table-tbody {
    .ant-table-cell {
      padding: 14px 16px !important;
    }
  }
.custom-table .ant-table-thead th {
    padding: 16px 16px !important;
  }

.label-title-text {
  font-size: 18px;
}

.emptychart-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.session-div-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-descriptions-item-label.list_head,
.ant-descriptions-item-content.list_head{
  background-color:#ddd !important; 
  border-radius:0 !important;
  
}
.ant-descriptions-item-label.list_head:first-child{
  border-top-left-radius: 0 !important;
}
.ant-descriptions-item-label.list_head span,
.ant-descriptions-item-content.list_head span{
  font-weight: 500;
  
}
.status-drawer .ant-drawer-header{
  padding: 16px 15px;
}
.lead_status_area{
  
  overflow:auto;
  
  
}
.opportunity_status_area{
 
  overflow:auto;
  
}
.status-drawer .ant-drawer-body{
  overflow: hidden;
  display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.status-drawer table tbody tr:first-child{
  position: sticky;
  top:0
}
@primary-color: #0f61db;@menu-dark-bg: #030D1D;@menu-light-bg: #e0e0e0;@layout-sider-background: #e0e0e0;@menu-dark-item-active-bg: #030D1D;@menu-highlight-color: #1CD9F2;@menu-dark-selected-item-text-color: #1CD9F2;@menu-dark-selected-item-icon-color: #1CD9F2;@menu-dark-item-hover-bg: #030D1D;@menu-light-item-hover-bg: #e0e0e0;@font-family: Gilmer;