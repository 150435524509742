@import "ThemeVariables.less";
// Color variables (appears count calculates by raw css)
@color0: #f0f2f5;
@color1: #808080;

.invite-dialogue .ant-modal-header{
  border-radius: 50px;
  border-bottom: none !important;
}

.dashboard-card {
  background-color: @color0;
}

.card-text {
  color: @color1;
  font-size: 12px;
  text-align: center;
}

.ant-card-body {
  padding: 10px;
  height: 100%;
}
// .site-card-wrapper .ant-card-body{
//   display: flex;
//   align-items: center;
// }

.card-description {
  height: 100px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.circle {
  border-radius: 50%;
  width: 38px;
  height: 38px;
  background: #fff;
  border: 2px solid @themecolor2;
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0em;
}

.dashboard-link {
  cursor: pointer;
  color: @themecolor1;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.dashboard-step-header {
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: center;
}

.float-container {
  padding: 5px;
}

.responsive-grid .ant-card-body{
  display: flex;
  align-items: center;
  padding: 10px;
  width:100%;
  justify-content: center;
}

.responsive-grid.connectAndInvite div:last-child{
  margin-right: 0 !important;
}

.float-child {
  float: left;
}

.float-child img{
  height: 37px;
}

.card-more-text {
  margin-left: 8px;
  width: 50%;
  padding-top: 10px;
}

.ant-card-head-title {
  white-space: unset;
}

.dashboard-card-header {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
}
.dashboard-card2-header {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
}
.dashboard-card-sub-header {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

.slick-arrow.slick-prev {
  background-image: url("../../assets/Images/previous.png");
  background-repeat: no-repeat;
}
.slick-arrow.slick-next,
.slick-next:focus {
  background-image: url("../../assets/Images/next.png");
  background-repeat: no-repeat;
}

.ant-carousel .slick-prev:hover,
.ant-carousel,
.ant-carousel .slick-prev:focus,
.ant-carousel {
  background: url("../../assets/Images/previous.png");
  background-repeat: no-repeat;
}

.ant-carousel,
.ant-carousel .slick-next:hover,
.ant-carousel,
.ant-carousel .slick-next:focus {
  background: url("../../assets/Images/next.png");
  background-repeat: no-repeat;
}

.slick-prev {
  display: block;
  padding: 0px !important;
  position: relative !important;
  z-index: 100;
  margin-top: -15px !important;
  padding-right: 10px !important;
  float: right;
}

.slick-next {
  display: block;
  position: sticky !important;
  padding-bottom: 20px !important;
  padding-top: 5px !important;
  margin-bottom: 30px !important;
  margin-top: -201px !important;
  float: right !important;
  top: 0 !important;
}

.blog-title-link {
  height: 80px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  padding-top: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  transition:0.5s ease-in-out !important; 
}
.blog-title-link:hover{
  color:#7F56D9 !important
}

.coaching-title-link {
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
}

.coaching-file-text {
  border: 1px solid @themecolor1;
  box-sizing: border-box;
  color: @themecolor1;
  margin-top: 10px;
  height: 35px;
  vertical-align: middle;
  padding-top: 5px;
  padding-left: 5px;
  width: 80%;
}

.coaching-file-video {
  border: 1px solid @themecolor1;
  box-sizing: border-box;
  color: @themecolor1;
  margin-top: 23px;
  height: 35px;
  vertical-align: middle;
  padding-top: 5px;
  padding-left: 5px;
  width: 80%;
  border-radius: 7px;
}

.file-icon {
  float: right;
  padding-right: 10px;
  cursor: pointer;
}

.total-planned {
  color: var(--secondaryColor);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-top:10px;  
  text-align: center;
}

.total-planned-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}

.dashabord-goal-charts-container {
  /* position: relative; */
  
  background: #fff;
}

.dashboard-goal-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
}

.dashbord-goal-chart {
  position: relative;
  width: 100%;
  height: 20px;
  border: none;
  width: 50%;
}
.dashbord-avg-chart {
  position: relative;
  width: 100%;
  height: 170px;
  border: none;
}

.dashbord-funnel-chart {
  position: relative;
  width: 100%;
  border: none;
}

.dashboard-sales-chart {
  position: relative;
  width: 100%;
  height: 20px;
  border: none;
}

.dashboard-marketing-chart {
  position: relative;
  width: 100%;
  height: 20px;
  border: none;
}

.sourced-sub-text {
  color: #727272;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.sourced-title-text {
  font-size: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.attachment-url-dashboard {
  display: inline-block;
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.card-description-dashboard {
  // height: 125px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  // text-align: justify;
  text-align: left;
  padding-top: 5px;
  color:rgb(127, 86, 217);
  font-weight: 500;
}

.card-description-dashboard-blog {
  height: 100px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 10px;
}

.center {
  margin: auto;
  padding-bottom: 40px;
}

.center-message-avg-chart {
  padding-top: 70px;
  padding-left: 25%;
}

.coaching-title {
  padding-top: 10px;
  padding-left: 17px;
  padding-bottom: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}

.chart-label-title {
  margin-top: 8px;
  margin-bottom: 12px;
  font-family: Gilmer;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.avg-sales-chart-label {
  margin-top: 25px;
  margin-bottom: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.funnel-chart-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 10px;
  color: @themecolor2;
}

.funnel-chart-planned-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 10px;
  color: @themecolor1;
}

.blog-desc {
  padding-top: 14px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.connect-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 0px;
}

.invitatin-btn {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
}

.link-now-btn {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.invite-width-empty {
  width: 70%;
}

.invite-width-filled {
  width: 100%;
}

.invite-btn-empty {
  padding-top: 14%;
}

.invite-btn-filled {
  // padding-top: 55%;
  margin-bottom: 10px;
}

.invite-section-empty {
  padding-top: 100%;
}

.invite-section-filled {
  padding-top: 70%;
}

.connect-filled {
  padding-top: 20px;
}

.link-now-link a {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #0f61db;
  // padding-right: 8px;
}

.ant-carousel {
  padding: 10px 0 10px 10px;
}

.carousel.carousel-slider{
  overflow: visible !important;
}

.carousel.carousel-slider .control-arrow{
  background-color: white !important;
}

.carousel.carousel-slider .control-next {
  top: -70px !important;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
  background-image: url("../../assets/Images/next.png") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 2px solid #7F56D9 !important;
  transition:0.5s ease-in-out !important;
}

.carousel.carousel-slider .control-prev {
  top: -70px !important;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
  background-image: url("../../assets/Images/previous.png") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 2px solid #7F56D9 !important;
}

.carousel.carousel-slider .control-next::before,
.carousel.carousel-slider .control-prev::before{
  border: none !important;
}

.carousel.carousel-slider .control-next:hover {
  top: -70px !important;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
  background: url("../../assets/Images/next.png") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 2px solid #7F56D9 !important;
}
.carousel.carousel-slider .control-prev:hover {
  top: -70px !important;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
  background: url("../../assets/Images/previous.png") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 2px solid #7F56D9 !important;
}

.carousel .control-prev.control-arrow{
  left: -33px !important;
}
.control-next {
  // position: fixed !important;
  padding-bottom: -43px;
  margin-top: 25px !important;
  margin-right: 17px;
  height: 20px;
  width: 10px;
  // padding-right: 22px !important;
  top: -20px !important;
}

.control-prev {
  // position: fixed !important;
  padding-bottom: -43px;
  margin-top: 25px !important;
  margin-right: 17px;
  height: 20px;
  width: 10px;
  padding-right: 22px !important;
  top: -20px !important;
  margin-left: 95% !important;
}

.carousel .control-disabled.control-arrow {
  opacity: 0 !important;
  // display: block !important;
  // cursor: inherit !important;
}

.invite .ant-modal-content {
  height: auto !important;
}

.invite-btn-empty-width {
  width: 70%;
}
.invite-btn-filled-width {
  width: 100%;
}

.linked-filled {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #0f61db;
  vertical-align: middle;
}

.link-now-filled {
  display: inline-block;
}
.ant-table-body::-webkit-scrollbar , ::-webkit-scrollbar{
  background-color: @color0 !important;
  width: 4px !important;
  height: 4px !important;
}
.ant-table-body::-webkit-scrollbar-thumb , ::-webkit-scrollbar-thumb{
  background-color: @color1 !important;
  border-radius: 7px !important;
}
.course-image-placeholder{
 
  .ant-skeleton{
    position: absolute;
    z-index: 0;
    width: 94%;
  }
  .img{
    position: relative !important;
  }
}
.dashboard-table{
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table{
    border: none;
  }

}
@primary-color: #0f61db;@menu-dark-bg: #030D1D;@menu-light-bg: #e0e0e0;@layout-sider-background: #e0e0e0;@menu-dark-item-active-bg: #030D1D;@menu-highlight-color: #1CD9F2;@menu-dark-selected-item-text-color: #1CD9F2;@menu-dark-selected-item-icon-color: #1CD9F2;@menu-dark-item-hover-bg: #030D1D;@menu-light-item-hover-bg: #e0e0e0;@font-family: Gilmer;