/* mobile screen  */
@media screen and (min-width: 320px) and (max-width: 780px){
    .carousel .control-prev.control-arrow {
        left: -71px !important;
    }
    .ant-layout-sider-collapsed{
        flex: 0 0 0px;
        max-width: 80px !important;
        min-width: 80px !important;
        width: 0px;
    }
    .mainLayout{
        margin: 15px !important;
    }
    .aboutPage {
        overflow: hidden;
    }
    .aboutPage .details{
        padding: 14px !important;
    }
    .aboutPage img{
        width: 100%;
    }
    .aboutPage .title{
        line-height: 32px;
        font-size: 24px !important;
    }
    .circle{
        width: 35px;
        height: 35px;
        font-size: 20px;
        padding-top: 0px;
        line-height: 31px;
    }
    .dashboard-step-header{
        font-size: 14px;
        line-height: 19px;
    }
    .responsive-grid img{
        height: 30px;
        margin-right: 10px;
    }
    .site-card-wrapper .responsive-grid{
        flex-direction: column;
    }
    .inviteWrapper{
        padding-left: 0 !important;
    }
    .invitatin-btn{
        position: relative !important;
        bottom: unset !important;
        margin: 20px 0 !important;
    }
    .userSettings form{
        display: flex !important;
        flex-direction: column !important;
    }
    .invitetab .inviteIMG {
        display: flex;
        width: 100%;
    }
    .invitetab .dashboard-card-header{
        text-align: center;
    }
    .invitetab .invite-btn-empty-width{
        width: 97% !important;
    }
    .yearSelector{
        margin-bottom: 20px;
    }
    .save-btn{
        margin-top: 20px;
    }
    .baselineMetricsTable{
        overflow: auto;
    }
    .total-closed-won-section{
        margin-left: 0 !important;
    }
    .right-arrow{
        display: none;
    }
    .pt-0{
        padding-top: 0 !important;
        padding-bottom: 20px !important;
    }
    .nextPage{
        margin-top: 40px;
        padding: 0 30px;
    }
    .ant-tabs-tab .ant-tabs-tab-btn{
        font-size: 16px;
    }
    .total-budget-amount{
        font-size: 22px;
    }
    .budget-planning-section{
        height: auto !important;
    }
    .budget-planning-section img{
        height: 0;
    }
    .budget-planning{
        margin-left: 0px !important;
    }
    .selected-column{
        width: 100px;
    }
    .campaign-planning-section .font-color-budget {
        justify-content: unset !important;
        margin-bottom: 10px;
    }
    .campaign-planning-section .font-color-budget .campaign-planning-title-spend {
        padding-left: 0!important;
        font-size: 17px;
    }
    .campaign-planning-section .budget-card-number-achieved{
        font-size: 18px;
    }
    .campaign-planning-section .font-color-budget button{
        margin-right: 10px;
    }
    .responsive-box .ant-col{
        margin: 5px 0;
    }
    .exportPDF-btn{
        transform: none !important;
    }
    .ant-table-body table , .revenue-table table{
        table-layout: unset !important;
    }
    .revenue-table .ant-table-header{
        overflow: unset !important;
    }
    .revenue-table .ant-table-container{
        overflow-x: scroll !important;
    }
    .revenue-table .ant-table-empty .ant-table-cell.ant-table-cell-scrollbar{
        display: none !important;
    }
    .ant-table-cell{
        width: 120px !important;
        min-width: 120px !important;
    }
    .exportPDF-btn{
        margin: 5px 18px !important;
    }
    .exportPDF-btn span:nth-child(1) svg{
        font-size: 10px;
    }
    .exportPDF-btn span:nth-child(2){
        display: none;
    }
    .salesMarketing{
        padding: 30px 10px !important;
    }
    .table-wrapper .column{
        min-width: 220px;
    }
    .table-wrapper .arrows{
        padding: 0;
        width: 30px;
    }
    .table-wrapper .CRMMapping{
        right: 30px;
        width: 220px;
    }
    .CRMMappingClosed{
        right: 30px;
    }
    .ant-drawer-content-wrapper{
        width: 100% !important;
    }
    .create-btn{
        transform: translate(-12%,-214%) !important;
    }
    #revenue_planning .p-10 , .revenue-table , .p-10{
        padding: 10px !important;
    }
    .revenue_calculation div.ant-row:nth-child(1){
        padding: 0 0 10px 0!important;
    }
    .quarterly-btn{
        margin: 0 20px;
    }
    .step2Box{
        margin-left: 7px !important;
    }
    .step2Box .marketing-slider{
        width: 92%;
    }
    /* .step2Box .marketing-slider .goal_textfield {
        margin-top: 20px;
    } */
    .marketing-slider{
        width: 100%;
    }
    .revenue-slider .marketing-slider {
        margin: 0 0 25px;
    }
    .marketing-slider{
        padding-left: 0 !important;
    }
    .linkedImg{
        padding-right: 0 !important;
    }
    .card-container{
        width: 100% !important;
    }
    .responsive-drawer{
        width: 100% !important;
    }
    .ant-modal-close {
        top: 0px;
        right: 30px;
    }
    .ant-modal-close svg{
        color: black;
        font-size: 17px;
    }
    .revenue_calculation{
        margin: 20px 0;
    }
    .pl-0{
        padding-left: 0!important;
    }
    .empty-funnel-section{
        padding: 10px !important;
        font-size: 20px !important;
    }
    /* .funnelchart canvas{
        width: 360px !important;
        height: 600px !important;
    } */
    /* .nextPage .goal_textfield{
        margin: 10px 0 30px;
    } */
    .budget-year .float-child{
        width: 100% !important;
        margin-left: 0!important;
        margin-bottom: 10px;
    }
    .card-select-budget{
        height: auto !important;
    }
    .budget-tab .ant-tabs-nav{
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: baseline;
        width: 100%;
    }
    .total-budget-content{
        width: 220px;
        height: auto;
        margin-bottom: 15px;
        padding-bottom: 10px;
    }
    .budget-tab .ant-tabs-tab{
        margin: 0 12px 0 0;
    }
    .budget-tab .ant-tabs-nav-wrap{
        padding-left: 9px !important;
    }
    .budget-tab .quarter-filter{
        display: block !important;
        padding-left: 0px;
        font-size: 15px;
    }
    .budget-tab .quarter-filter div{
        margin-top: 10px;
    }
    .revenue_calculation{
        height: auto !important;
    }
    .budget-active-status div{
        font-size: 17px;
    }
    .budget-active-status img{
        height: 20px !important;
        margin-bottom: 2px !important;
    }
    .budget-planning-section.non-print{
        margin: -2px 8px 0px -4px !important;
    }
    .budget-planning-section.non-print .text{
        padding-left: 8px !important;
    }
    .budget-planning-section.non-print .text span{
        margin-left: 2px !important;
    }
    .budget-planning-section.non-print .text button{
        font-size: 12px !important;
    }
    .budget-tab .table-responsive{
        overflow-x: scroll;
        margin: 0 14px;
    }
    .budget-tab .table-responsive .wrapper{
        width: 500px !important;
        padding: 0 !important;
    }
    .budget-planning-reset{
        float: unset !important;
    }
    .createBTN{
        padding: 0 !important;
        display: unset !important;
    }
    .print-icon{
        margin-left: 14px !important;
    }
    .campaign-table.empty-state{
        overflow: scroll;
    }
    .coaching-header-section{
        padding: 0 10px !important;
    }
    .coaching-sub-header , .aboutPage .header{
        font-size: 25px;
        padding-top: 10px ;
    }
    .checkbox-container-global{
        margin-top: 10px !important;
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper{
        margin-left: 0!important;
    }
    .funnelTrends{
        text-align: left !important;
        display: flex;
        flex-direction: column;
    }
    .channel-campaign-table .ant-table-content{
        overflow: scroll !important;
    }
    .centered{
        width: 100%;
        padding: 10px !important;
        top: 150% !important;
    }
    .preview-info-buttons{
        flex-direction: column;
    }
    .preview-info-buttons div{
        padding: 0!important;
        margin-bottom: 10px;
    }
    .coaching-card img{
        height: auto !important;
    }
    .setting-table .ant-descriptions-row{
        display: flex;
        flex-direction: column;
    }
    .Checked {width: 100% !important;
        padding-left: 0 !important;
    }
    .suspendBTN{
        width: 100% !important;
    }
    .integrationTab li{
        grid-template-columns: 100% 100% !important;
    }
    .analyticsList{
        flex-direction: column;
        margin: 10px 0;
    }
    .invite-dialogue button{
        width: 100% !important;
        margin-bottom: 10px;
    }
    .syncBox{
        margin: 20px 0px 10px 0px !important;
    }
    .package-list{
        padding: 0!important;
    }
    .user-container{
        padding-top: 0!important;
    }
    .componiesForm , .componiesForm .ant-space-horizontal , .packageListBox .ant-space-horizontal{
        flex-direction: column;
        width: 100%;
    }
    .componiesForm .ant-select-lg , .componiesForm .ant-space-item , .componiesForm .ant-space-item button ,.packageListBox .ant-select-lg{
        width: 100% !important;
    }
    .componiesForm .ant-space-item {
        margin-right: 0 !important;
    }
    .adminTable{
        margin-top: 30px;
    }
    .table-wrapper{
        overflow-x: scroll;
    }
    .viewingBox{
        float: right!important;
        padding-right: 0 !important; 
        position: absolute;
        left: 0;
        top: -21px;
    }
    .viewingBox .back-to-admin-link{
        background: white;
        padding: 4px !important;
        border: none !important;
    }
    .packageListBox .plr-0{
        padding-left: 0 !important;
        padding-right: 0 !important;
        left: 0!important;
        width: 95%;
    }
    .packageListBox button{
        width: 100% !important;
    }
    .packageListBox .ant-space-item , .edit-package{
        margin-right: 0 !important;
        padding-right: 0 !important;
        width: 100%;
    }
    .packageListBox .ant-table-cell div{
        flex-direction: column;
        width: 100% !important;
    }
    .packageListBox .assign-text{
        text-align: left;
    }
    .graph-box{
        max-height: 100%;
    }
    .graph-box div{
        padding-left: 0 !important;
        text-align: center;
    }
    .createNewStageBtn{
        position: relative !important;
        transform: none !important;
        display: flex;
        justify-content: flex-end;
    }
    .salesMarketingRow{
        display: flex;
        flex-direction: column;
    }
    .CurrentPlan {
        padding: 0 !important;
    }
    .SearchAssets {
        flex-direction: column;
        justify-content: center;
    }
    .SearchAssets .assetSearch , .SearchAssets .ant-select{
        width: 100% !important;
        margin: 10px 0 !important;
    }
    .AssetLibrary .ant-list-item{
        position: relative;
        flex-direction: column !important;
    }
    .AssetLibrary .ant-list-item-meta , .AssetLibrary .webinarList .ant-list-item-meta-content{
        width: 100% !important;
    }
    .AssetLibrary .webinarList .ant-list-item-meta{
        flex-direction: column !important;
        position: relative;
    }
    .AssetLibrary .webinarList .ant-list-item-meta-content{
        margin: 10px 0;
    }
    .AssetLibrary .ant-list-item-action{
        position: absolute;
        right: 0;
        top: 0;
    }
    .AssetLibrary .ant-list-item-action li{
        top: 4px !important;
    }
    .AssetLibrary .whitepaper .ant-list-item-action{
        position: unset;
        right: unset;
        top: unset;
        margin-left: auto;
    }
    .AssetLibrary .whitepaper .ant-list-item-action li{
        top: unset !important;
    }
    .table .data{
        border-left: 1px solid #D9D9D9;
    }
    .selectModalTypeBtn{
        margin: 10px auto;
    }
    .ModalTypebox img{
        margin: 10px auto !important;
        display: flex
    }
    .ModalTypebox .ant-col{
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .ModalTypebox .ant-col.ant-col-8{
        flex-direction: column;
        margin-bottom: 30px;
    }
    .sdr .ant-table-container{
        overflow: auto;
    }
    .sdr .ant-table-container th.ant-table-cell{
        min-width: 170px !important;
    }
    .gtm .w-100{
        width: 100%;
    }
    .gtm .details{
        padding: 0px 0px 0px 10px !important;
    }
    .exportBox button, .exportBox a{
        width: 100% !important;
        margin: 5px 0;
    }
    .VideoWrapper img{
        height: auto !important;

    }
    /* #revenue_planning .ant-row.p-10 .optional_icon.top63 {
        top: 63px !important;
    } */
    .marketing-sourced-info-icon {
        padding-right: 22px;
    }
    .step3{display: unset !important;}
    .buttonGrp{ 
        padding: 0 !important; 
        display: flex;
        justify-content: space-around;
    }
    .buttonGrp button{
        margin: 0 !important;
    }
    .annual-goal-header-text, .annual-goal-sub-header-text{
        padding-left: 11px;
    }
    .right_side_label{justify-content: center !important;}
    .filter-section{
        padding: 20px ;
    }
    .date-picker{
        transform: none !important;
    }
    .radio-options{
        padding-left: 0 !important;
    }
    .filter-options{
        flex-direction: column;
    }
    .filter-options div{
        width: 100% !important;
    }
    .coaching-tab{
        margin-top: 10px;
    }
    .course-description-section{
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .title-course-video{
        padding-left: 20px !important;
        margin-top: 0 !important; 
    }
    .course-video-section{
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
    .course-material{
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    #course-image-banner-div img{
        height: auto !important;
    }
    .similar-courses, .coaching-title{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .plan-header{
        flex-wrap: nowrap !important;
    }
    .plan-header img{
        margin-right: 10px !important;
    }
    .ant-tabs-extra-content{
        padding-left: 0 !important;
    }
    .ant-picker-panel-container .ant-picker-panels{
        flex-direction: column !important;
    }
    .course-image-placeholder{
        overflow: hidden;
    }
    .sandbox-wrapper{
        padding-right: 8px !important;
        margin-bottom: 10px !important;
    }
    .sandbox-wrapper button{
        margin-left: 15px;
    }
}

/* ipad  */
@media only screen and (min-width: 768px) and (max-width: 1024px){
    .ant-layout-sider-collapsed{
        flex: 0 0 0px;
        max-width: 80px !important;
        min-width: 80px !important;
        width: 0px;
    }
    .mainLayout{
        margin: 15px !important;
    }
    .site-card-wrapper .responsive-grid{    
        display: grid !important;
        grid-template-columns: 50% 50%;
    }
    .responsive-grid.connectAndInvite{
        display: flex;
        flex-direction: column;
    }
    .inviteWrapper{
        padding-left: 0 !important;
    }
    .baselineMetricsTable{
        overflow: auto;
    }
    .exportPDF-btn{
        margin: 5px 20px !important;
        transform: translate(0%, 87%) !important;
    }
    .createNewStageBtn{
        transform: none !important;
        right: 20px !important;
    }
    .aboutPage .details .title{
        font-size: 25px !important;
    }
    .marketing-sourced-info-icon {
        padding-right: 0;
    }
    .more-info-btn {
        display: flex;
        justify-content: center;
        padding-top: 0 !important;
        padding-bottom: 10px;
    }
    .revenue-sub-title.step3{
        display: block !important;
    }
    .spacing-row{   
        padding: 15px 20px !important;
    }
    .total-budget-content{
        padding-top: 0 !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .total-budget-text,
    .total-budget-amount{
        font-size: 16px;
    }
    .table-wrapperr , .sdr-table , .channel-campaign-table .ant-table-wrapper{
        overflow-y: scroll;
    }
    .gutter .ant-col{
        margin-bottom: 15px;
    }
    .graph-box{
        max-height: 100% !important;
    }
    .suspendBTN{
        width: auto !important;
    }
    .connect-for-help{
        margin-top: 10px;
        margin-left: 10px;
    }
    .table-scroll{
        overflow-y: scroll !important;
    }
    .packageListBox .plr-0{
        left: 0 !important;
        text-align: center; 
        margin-bottom: 10px;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .course-list-admin .ant-pagination {
        margin: 16px 10px !important;
    }
    
    .SalesForce .linkedImg{
        padding-right: 0px !important;
        height: 17px !important;
    }
    .sandbox-wrapper{
        padding-right: 8px !important;
        margin-bottom: 10px;
    }
}