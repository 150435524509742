:root {
  --gray300: rgba(208, 213, 221, 1);
  --white: #ffffff;
  --bodyText: #475467;
  --primaryColor: #7F56D9;
  --secondaryColor: #EC4A0A;
  --titleText:#1D2939;
  --lightText:#98A2B3
}
/***** general classes *****/
.titleText{color:#1D2939}
.bodyText{color:#475467}
.fw400{font-weight: 400;}
.fw500{font-weight: 500;}
.fw600{font-weight: 600;}
.fw700{font-weight: 700;}
.fw800{font-weight: 800;}
.primaryColor{color:#7F56D9;}
.secondaryColor{color:#EC4A0A}
.textCenter{text-align:center}
.lightText{color:#98A2B3}
.yellowForecastBg{
  background-color: #FEDF89;
  color:#7A2E0E;
  border:1px solid #DC6803;
}
.redForecastBg{
  background-color: #FECDCA;
  color:'#7A271A';
  border:1px solid #D92D20;
}
.greenForecastBg{
  background-color: #A6F4C5;
  color:#054F31;
  border:1px solid #039855;
}
.lyellowForecastBg{
  background-color: #FFFCF5;
  color:#7A2E0E;
  border:1px solid #FEC84B;
}
.lredForecastBg{
  background-color: #FFFBFA;
  color:#7A271A;
  border:1px solid #FDA29B;

}
.lgreenForecastBg{
  background-color: #F6FEF9;
  color:#054F31;
  border:1px solid #6CE9A6;
}
.headerMain{
  font-size: 36px !important;
  line-height: 46px !important;
}
.subHeaderMain{
  font-size: 18px !important;
  line-height: 28px !important;
}
.header2{
  font-size: 20px !important;
  line-height: 28px !important;
}
.sectionTitle{
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  color:#1D2939 !important;
}
.mainLayout{position: relative;}
/***** breadcrumb *****/
.breadcrumb_main .breadcrumb_item{text-transform: uppercase;}
.breadcrumb_main .breadcrumb_item.home, .breadcrumb_main .breadcrumb_item.home a{
  font-size: 12px;
  font-weight:400;
  line-height: 16px;
  color:#667085
}
.breadcrumb_main .breadcrumb_item.current{
  font-size: 12px;
  font-weight:700;
  line-height: 16px;
  color: var(--bodyText);
}
.breadcrumb_main .breadcrumb_item.seperator{
  display: inline-block;
  padding:0 5px;
}
.breadcrumb_main .breadcrumb_item.seperator svg{
  font-size: 12px;

}

/***** buttons *****/
button.tertiaryButton{
  height: 40px;
  width: 220px;
  border: 1px solid var(--gray300);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color:#344054;
  background-color: transparent;
  transition: all 0.5s ease-in-out !important;
}
button.tertiaryButton span{font-weight: 700;}
button.tertiaryButton:hover,button.tertiaryButton:focus{
  background-color: #F9FAFB;
  border: 1px solid var(--gray300);
  color:#344054;
  border-radius: 40px;
}

button.primaryButton{
  height: 40px;
  width: 220px;
  border: 1px solid var(--primaryColor);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color:#fff;
  background-color: var(--primaryColor);
  transition: 0.5s ease-in-out !important;
  text-transform: capitalize;
}
button.primaryButton span{font-weight: 700;}
button.primaryButton:hover,button.primaryButton:focus{
  background-color: #53389E;
  border: 1px solid #53389E;
  color:#fff;
  border-radius: 40px;
}
button.primaryButton:disabled{
  background-color: #D6BBFB !important;
  border: 1px solid #D6BBFB !important;
  color:"#1D2939" !important;
}

button.secondaryButton{
  height: 40px;
  width: 220px;
  border: 1px solid #F9F5FF;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color:#6941C6;
  background-color: #F9F5FF;
  transition: 0.5s ease-in-out !important;
  text-transform: capitalize;
}
button.secondaryButton span{font-weight: 700;}
button.secondaryButton:hover,button.secondaryButton:focus{
  background-color: #F4EBFF;
  border: 1px solid #F4EBFF;
  color:#6941C6;
  border-radius: 40px;
}
button.secondaryButton:disabled{
  background-color: #FCFAFF !important;
  border: 1px solid #FCFAFF !important;
  color:"#D6BBFB" !important;
}

body {
  margin: 0;
  font-family: "Gilmer", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#explorics-container .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#explorics-container .trigger:hover {
  color: #1890ff;
}

#explorics-container .user-option {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}


#explorics-container .logo {
  height: 32px;
  background: #f0f2f5;
  margin: 16px;
}

#explorics-container #site-layout .site-layout-background {
  background: #fff;
}
#explorics-container #site-layout {
  /* margin-left: 250px; */
  z-index: 1;
  transition: 0.2s;
}
.ant-layout.layout {
  margin-left: 80px;
  z-index: 1;
  transition: 0.2s;
}
.ant-layout.layoutCollapsed{
  margin-left: 280px;
}

.ant-btn[disabled]{
  background-color: transparent !important;
}

.ant-layout-sider-zero-width-trigger{
  position: absolute !important;
  top: 9px !important;
  right: -44px !important;
  z-index: 10 !important;
  color: black !important;
  background-color: white !important;
}

#explorics-container .ant-menu-inline > .ant-menu-item , #explorics-container .ant-menu-submenu-title{
  height: fit-content;
  display: flex;
  align-items: center;
}
#explorics-container .ant-menu-inline > .ant-menu-item:first-child{
  margin-top:0 !important;
}
#explorics-container .ant-menu-submenu-title{
  line-height: 26px;
}
#explorics-container .ant-menu-inline > li{
  margin: 18px 0 !important;
  padding:7px 0;
}
.subMenuItem .ant-menu-item > .ant-menu-item-only-child > li{
  padding-left: 34px !important;
}

.ant-menu-submenu-title .anticon{
  margin-right: 5px !important;
}
#explorics-container li.ant-menu-item-only-child{
  margin: 0 0 !important;
  padding: 10px 0 10px 60px !important;
}

#explorics-container .ant-menu-inline > .ant-menu-item > span {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
#explorics-container .ant-menu-inline > .ant-menu-item > span.anticon{
  margin-right: 5px;
}

#explorics-container .ant-menu-inline > .ant-menu-item > span > a{
    width: 100%;
    white-space: pre-line;
    text-align: center;
}

#explorics-container .ant-menu-dark .ant-menu.ant-menu-sub.ant-menu-inline {
  background: rgba(255, 255, 255, 0.05);
}


/* #explorics-container .ant-menu-inline,
#explorics-container .ant-menu-vertical,
#explorics-container .ant-menu-vertical-left {
  border-right: 1px solid #fff;
} */

.explorics-sider-menu {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 5px;
  border-right: 1px solid #c9c9c9;
}
.sidebar-menu {
  margin-top: 15px !important;
  overflow: scroll;
  height: calc(100vh - 183px)
}

.sidebar-menu ::-webkit-scrollbar,
::-webkit-scrollbar {
  display: none !important;
}

.ant-divider-vertical{
  border-left: 1px #A9A9A9 solid !important;
}
.ant-divider-horizontal{
  border-top: 1px #A9A9A9 solid !important;
}
.main_background {
  background-color: rgb(240, 242, 245);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.login_content {
  background-color: #ffffff;
  margin-top: 40px;
  width: 450px;
  border-radius: 5px;
  flex: initial !important;
  padding: 40px 30px 25px;
}

.company_logo {
  width: 100%;
  height: 164px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.company_logo svg {
  height: 100%;
}

.login_symbol {
  text-align: center;
  padding: 0px 0 5px;
  font-size: 28px !important;
  font-weight: 700 !important;
}

.textfield_section {
  margin: 20px 0 0;
}

.user_textfield {
  padding: 30px 15px;
  margin: 15px 0;
}

.user_textfield > input {
  font-size: 22px;
}
.user_textfield > span {
  font-size: 22px;
  padding: 0 5px 0 0;
  color: #1890ff;
}
.user_textfield > .ant-input-suffix {
  padding: 0;
  color: #000000 !important;
}
.remember_labal > label > span,
.remember_labal > a {
  font-size: 18px;
}
.login_button {
  width: 100%;
  font-size: 22px !important;
  margin: 10px 0;
  padding: 10px 0 !important;
  height: auto !important;
}
.remember_forget_pass {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
}
.register_label {
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
}

.ant-form-item-label > label {
  display: block !important;
}
.create_main_div {
  min-height: 100vh !important;
  height: 100vh;
  width: 100vw;
  padding: 3em 0px 0px;
  background-color: rgb(246, 246, 246) !important;
  display: grid;
  grid-template-rows: 1fr 64px;
}

.create_account_content {
  background-color: #ffffff;
  margin-top: 40px;
  max-width: 600px;
  border-radius: 5px;
  flex: initial !important;
  padding: 24px;
}

.logoImage {
  width: 100%;
  height: 96px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.textfield_label {
  margin-bottom: 24px;
}
.textfield_with_label {
  padding: 11px 18px !important;
  font-size: 20px !important;
  line-height: normal;
}
.side_by_side_button {
  display: flex;
  margin-bottom: 24px;
}
.create_account_left_button {
  width: 100%;
  margin-left: 5px;
}
.create_account_right_button {
  width: 100%;
  font-size: 15px !important;
  margin-right: 5px;
  padding: 5px !important;
  height: auto !important;
}
.create_account_heading {
  color: #33444c !important;
  font-weight: 400 !important;
  font-size: 24px;
}

.label_text {
  font-weight: 400 !important;
  font-size: 16px !important;
}

input.xl {
  padding: 11px 18px;
  font-size: 18px;
}

.ant-form-item-label > label,
.ant-form-item-label > label > .ant-row {
  width: 100%;
}

.border_bottom {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 0 10px !important;
}
.right_side_label {
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  text-align: right;
  align-self: flex-end;
}

.text_label {
  color: #303030;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 10px;
}
.optional_icon {
  color: rgb(0, 0, 0, 0.5) !important;
}
.text_bottom {
  color: rgb(0, 0, 0, 0.25) !important;
}
.goal_textfield > span > span {
  padding: 5px;
}
.create-campaign .anticon-dollar-circle , .CreateBudgetProfile .anticon-dollar-circle{
  top: 22px !important;
}
.create-campaign .ant-drawer-header-title{
  flex-direction: row-reverse;
}
.CreateBudgetProfile .ant-drawer-header-title{
  flex-direction: row-reverse;
}
.anticon-dollar-circle{
  z-index: 12;
  top: 43px;
  left: 20px;
  font-size:18px;
}
.goal_textfield > input,
.goal_textfield > div > input {
  line-height: 1;
}
/* .revenue_calculation {
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid #f1f1f1;
} */

.arrow_sign {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.arrow_sign > span {
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 50%;
  align-items: center;
  vertical-align: middle;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  z-index: 100;
}
.arrow_sign > span > svg {
  font-size: 30px;
  color: gray;
}
.dropdown_button:last-child > span {
  border-left: none;
}

.dropdown_button:first-child > button {
  padding-right: 0 !important;
}
.ant-modal-header > div {
  text-align: center;
  font-size: 20px;
}
.ant-slider-track {
  background-color: #1890ff !important;
}
.ant-slider-handle {
  border-color: #1890ff !important;
}
.subMenuItem {
  font-size: 18px !important;
  /* height: 50px;
  padding: 5px 0 !important; */
}
.subMenuItem > div > span {
  font-size: 18px !important;
}
.subMenuItem > div {
  margin-top: 0;
  margin-bottom: 0;
}
/* .subMenuItem .ant-menu{
  font-size: 13px !important;
  padding: 7px !important;
} */
.subMenuItem .ant-menu-title-content{
  font-size: 14px !important;
  padding: 0 0 0 0px !important;
}
.marketing_source_block {
  border: 1px solid #e5e5e5;
  border-radius: 7px;
  margin: 10px;
}

@media print {
  body {
    visibility: hidden;
  }
  main {
    visibility: visible;
  }
  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }
  #section-to-print,
  main {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.budgetLeadsViewTableGearIcon {
  cursor: pointer;
  visibility: hidden;
}

.budgetLeadsViewTableRow:hover .budgetLeadsViewTableGearIcon {
  visibility: visible;
}

.deleteCampaignModal .ant-modal-content {
  height: unset !important;
}

.deleteCampaignModal .ant-modal-body {
  padding: 24px;
}

.preventNavModal .ant-modal-content {
  height: unset;
}

.preventNavModal .ant-modal-body {
  padding: 24px;
}

.preventNavModal .ant-modal-title {
  text-align: left;
}

.budgetLeadsViewTableFirstCell {
  display: flex;
}

.sub-container {
  padding: 15px;
  background: #fff;
  border-radius: 7px;
}

.revenue-sub-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
}

.ant-input-number {
  height: 44px;
  border: 1px solid #dedede;
  border-radius: 7px !important;
}

.ant-slider-rail {
  background-color: rgba(15, 97, 219, 0.21) !important;
}

.goal_textfield .ant-input-number-input-wrap {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.total-won-text {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  display: block;
}
.total-won-count {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  color: #0f61db;
  display: block;
}
.total-won-text-closed {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  color: #0f61db;
  display: block;
}

.total-closed-won-section {
  border: 1px solid #e5e5e5;
  height: 94%;
  padding: 10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  border-radius: 7px;
}

.marketing_source_block .ant-typography + h5.ant-typography {
  margin-top: 0em !important;
}

.more-info {
  border: 1px solid #e5e5e5;
  padding: 10px;
  border-radius: 7px;
  color: #a9a9a9;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  cursor: help;
  float: right;
}

.sourced-title {
  width: 100%;
  border-bottom: 1px solid rgb(229, 229, 229);
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #0F61DB !important;
}

.source-title-sales {
  color: #00AEC5 !important;
}
.sourced-amount {
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 26px;
letter-spacing: 0em;
text-align: left;
color: #303030;
}

.sourced-text {
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: left;
color: #727272;
}

.sales-won-amount {
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 26px;
letter-spacing: 0em;
text-align: left;
color: #00AEC5;
}
.marketing-won-amount {
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 26px;
letter-spacing: 0em;
text-align: left;
color: #0F61DB;
}

.right-arrow {
  position: absolute;
    top: 44%;
    margin-left: -26px;
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    padding: 10px;
}

.annual-goal-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 15px;
    color: #303030;
}
.annual-goal-header-text {
  font-family: Gilmer;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
    color: #303030;
    padding-top: 20px;
    padding-left: 20px;
}
.annual-goal-sub-header-text {
  font-family: Gilmer;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #727272;
  padding-top: 5px;
  padding-left: 20px;
  padding-bottom: 30px;
}

.empty-funnel-section {
  border: 1px solid#e5e5e5;
  margin: 15px !important;
  border-radius: 7px;
}

.conversation-difference-dot {
  height: 10px;
  width: 10px;
  background-color: var(--secondaryColor);
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  margin-top: 13px;
}

.marketing-sourced-info-icon {
  padding-right: 118px;
}

.admin-title {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  }

  .analytics-tab .ant-tabs-nav {
    height: 60px;
  }
  .analytics-tab{
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .analytical-tab-2 .ant-tabs-content-holder {
    background: #fff;
  }
  .analytical-tab-2 .ant-tabs-nav-wrap {
    padding-top: 15px;
  }
  .back-to-admin-link{
    border: 1px solid #D0D5DD;
    border-radius: 7px;
    padding: 10px;
  }
  .ant-popover-buttons{
    display: flex ;
    flex-direction: row-reverse ;
    justify-content: center;
  }

  .container {
    position: relative;
    text-align: center;
    color: white;
  }

  .centered {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 35px;
    border: 1px solid #727272;
    border-radius: 7px;
  }

  .preview-info {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #303030;
    padding-top: 20px;
  }

  .preview-info-buttons {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 22px;
  }

  .learn-more-btn {
    height: 38px !important;
    margin-left: 5px;
    border: 1px solid rgb(48, 48, 48) !important;
    color: rgb(48, 48, 48) !important;
  }

.setting-table .ant-descriptions-item-label , .setting-table .ant-descriptions-item-content{
  padding: 9px 19px !important;
}

.create-package-drawer .ant-drawer-header-title, .create-new-course .ant-drawer-header-title, .AddCompany .ant-drawer-header-title,
.CreateNewChannel .ant-drawer-header-title, .createAssetModal .ant-drawer-header-title
{
  flex-direction: row-reverse;
}


.wrapperBox {
  height: 100%;
}
#quick-sight{
  height: 100%;
}
.small-indicator{
  height: 5px;
  width: 5px;
  background-color: var(--primaryColor);
  border-radius: 10px;

  display: inline-block;
  margin-right: 8px;
  margin-top: 0px;
}

.dashboard-chart-skeleton{
  width: 24vw !important;
  height: 200px !important;
}
.ant-layout-sider-collapsed{
  flex: 0 0 0px;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 0px;
}
.filter__dropdown .ant-switch-checked{
  background-color:green;
}
.filter__menu li:hover{
  background-color: transparent;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item{
  align-items: center;
}
.social_images{
    border: 1px solid rgba(227, 227, 227, 1);
    padding: 1px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}
.social_default_image{
  background-color: rgba(239, 112, 70, 1);
  height: 36px;
  width:36px;
  border-radius: 4px;
  display:flex;
  align-items: center;
  justify-content: center;
}
.panel-design{
  border-radius:7px;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 3px 6px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  background: #fff;
}
.executive-tabs .ant-tabs-nav,.executive-tabs .site-card-wrapper{
  border-radius:7px;
  border: 1px solid var(--Gray-300, #D0D5DD);
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}
.general-table .ant-table table tr th{border-radius: 0 !important; padding:15px 8px !important;}
.general-table .ant-table table tr th .ant-typography{
  color:var(--titleText);
  font-weight: 500;
}
.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td
{
  border-right: 1px solid #D0D5DD !important;
  border-bottom: 1px solid #D0D5DD !important;
}
.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.general-table .ant-table-summary > tr > th, .general-table .ant-table-summary > tr > td{
  border-bottom: 1px solid #D0D5DD !important;
  padding:10px 8px;
}
.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table{
  border:1px solid #D0D5DD !important;
}
.link-icon svg{
  color:var(--primaryColor)
}
.general-tab .ant-tabs-nav-wrap{background: #f5f7fa; background-color: #f5f7fa !important;}
.general-tab .ant-tabs-tab{padding:18px 0}
input.general-input{transition: 0.5s ease-in-out !important; color:#1D2939; border:1px solid #D0D5DD; height: 45px;}
textarea.general-input-textarea{transition: 0.5s ease-in-out !important; color:#1D2939; border:1px solid #D0D5DD;}
input.general-input:focus,textarea.general-input-textarea:focus{
  box-shadow: none !important;
  border:1px solid var(--primaryColor) !important;
}
input.general-input::placeholder,textarea.general-input-textarea::placeholder{
  color:#98A2B3
}
.general-input-select{transition: 0.5s ease-in-out !important;}
.general-input-select .ant-select-selector{
  height: 45px !important;
  color: #1D2939;
  border: 1px solid #D0D5DD !important;
  align-items: center;
}
.general-input-select.ant-select-focused .ant-select-selector{
  box-shadow: none !important;
  border: 1px solid #7F56D9 !important;
}
.general-input.ant-input-affix-wrapper-focused{
  box-shadow: none !important;
  border: 1px solid #7F56D9 !important;
}
.general-input.ant-input-password input{
  height: 45px !important;
  color: #1D2939;
  align-items: center;
}
.general-form label small{
  font-size: 16px;
  color:#1D2939;
  font-weight:500
}
.add-icon svg path{
  fill:var(--secondaryColor)
}
.add-icon svg path:last-child{
  fill:#fff
}
input.general-input[type=search]{
  transition: 0.5s ease-in-out; color:#1D2939
}
select.general-input:focus{
  box-shadow: none !important;
  border:1px solid var(--primaryColor) !important;
}
.general-tab .ant-form-item-control-input-content{
  display: flex;
  flex-wrap: wrap;
}
.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner{
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
}
.ant-checkbox-checked::after{
  border:1px solid var(--primaryColor);
}
.revenue-planning-table table tbody tr td:first-child, .revenue-planning-table table tbody tr td:nth-child(3){text-align: center;}
.ant-slider-handle{
  border: 2px solid #7F56D9 !important;
}
.ant-slider-track {
  background-color: #7F56D9 !important;
}
input[type="checkbox"]{
  width:18px;
  height:18px;
  accent-color: #7F56D9;
  color:#1D2939;
  
}

.ant-radio-inner{width:20px !important; height:20px !important;}
.ant-radio-checked::after{    border: 1px solid #7F56D9 !important;}
.ant-radio-checked .ant-radio-inner{border-color: #7F56D9 !important;}
.ant-radio-inner::after{
  background-color: #7F56D9 !important;
}
input[type="checkbox"] + span{
  color:#1D2939;
  font-weight: 500;
  margin-left:2px;
  font-size: 17px;
}

.campaign-table.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.campaign-table.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th{
  border-right: 1px solid #D0D5DD;
  border-bottom: 1px solid #D0D5DD;
}
/* .campaign-table.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td:first-child,
.campaign-table.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th:first-child{
  border-left:1px solid #D0D5DD;
} */
/* .campaign-table.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td:last-child,
.campaign-table.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th:last-child{
  border-right:1px solid #D0D5DD !important;
}
.campaign-table.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th:nth-last-child(2){
  border-right:1px solid #D0D5DD !important;
} */

.campaign-table.general-table .ant-table.ant-table-bordered {
  border: 1px solid #D0D5DD !important;
}
.ant-switch-checked{
  background-color: #7F56D9 !important;
}
.budget-tab.general-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color:#7F56D9;
}
.ant-tabs-ink-bar{
  background: #F04438 !important;
  height:'2.5px'
}
.budget-tab.general-tab .ant-tabs-tab .ant-tabs-tab-btn{
  color:#1D2939;
}
.executive-tabs .ant-tabs-tab{
  transition: 0.5s ease-in-out !important;
  padding:20px 10px;
  margin:0 0 0 16px !important
}
.executive-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn span{
  color:#7F56D9;
  font-weight: 700;
}
.executive-tabs .ant-tabs-tab .ant-tabs-tab-btn span{
  color:#1D2939;
  font-weight: 500;
  transition: 0.5s ease-in-out;
  font-size: 18px !important;
}
.executive-tabs .ant-tabs-tab:hover .ant-tabs-tab-btn span, .executive-tabs .ant-tabs-tab:hover .ant-tabs-tab-btn{
  color:#7F56D9
}
.general-table.sales-table .ant-table.ant-table-bordered{
  border:none !important;
}
.general-tab.first-tab .ant-tabs-tab{transition: 0.5s ease-in-out;}
.general-tab.first-tab .ant-tabs-tab .ant-tabs-tab-btn{
  font-size: 17px;
  font-weight: 700;
}
.general-tab.first-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color:#7F56D9;
}
.general-tab.first-tab .ant-tabs-tab:hover .ant-tabs-tab-btn{
  color:#7F56D9;
}
.coaching-tab.general-tab.first-tab .ant-tabs-tab .ant-tabs-tab-btn div{
  color:#1D2939 !important
}
.coaching-tab.general-tab.first-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn div{
  color:#7F56D9 !important
}
.coaching-tab.general-tab.first-tab .ant-tabs-tab:hover .ant-tabs-tab-btn div{
  color:#7F56D9 !important
}
.coaching-card .ant-card-body{padding:0}
.coaching-design.panel-design{border-radius: 0;}
.ant-input-number.general-input, .ant-input-number.general-input input{height:45px; width:100% !important}
.ant-typography-edit svg{
  fill:#7F56D9;
}
.ant-input-number.general-input svg{
  margin-top:6px
}
.ant-descriptions.ant-descriptions-bordered.account_description_table table tr th{
  background-color: #f5f7fa !important; 
  border-right: 1px solid #D0D5DD !important;
  border-bottom: 1px solid #D0D5DD !important;
  padding:14px 19px !important;
  
}
.ant-descriptions.ant-descriptions-bordered.account_description_table table tr th span{
  font-weight:700 !important;
}
.account_description_table table tr td{
  
  border-bottom: 1px solid #D0D5DD !important;
}
.ant-menu.right-dropdown-menu li span a{
  color:#1D2939 !important;
  font-weight:500 !important
}
.ant-menu.right-dropdown-menu li.ant-menu-item-selected{
  background-color: #7F56D9;
}
.ant-menu.right-dropdown-menu li.ant-menu-item-selected a{
  color:#fff !important
}
.ant-menu.right-dropdown-menu li a{
  transition:0.5s ease-in-out !important
}
.ant-menu.right-dropdown-menu li{
  transition:0.5s ease-in-out !important
}
.ant-menu.right-dropdown-menu li:hover{
  background-color: #7F56D9 !important;
}
.ant-menu.right-dropdown-menu li:hover a{
  color:#fff !important
}
.adminCompanyTable .ant-table table thead tr th div span{
  color:#1D2939 !important;
  font-weight:500
}
.adminCompanyTable .ant-table table tr td small{
  color:#1D2939 !important;
}
.adminCompanyTable .ant-table table tr td small.company-name{
  color:#7F56D9 !important;
}
.general-table .ant-pagination .ant-pagination-item{
  width:30px !important;
  height:30px !important;
  border:1px solid #D0D5DD !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin:0 5px !important;
  transition:0.5s ease-in-out !important;
  
}
.general-table .ant-pagination .ant-pagination-item.ant-pagination-item-active{
  background:#7F56D9 !important;
  
}
.general-table .ant-pagination .ant-pagination-item.ant-pagination-item-active a{
  color:#fff !important;
}

.general-table .ant-pagination.mini .ant-pagination-item a{
  font-weight: 700;
  transition:0.5s ease-in-out !important;
}
.general-table .ant-pagination .ant-pagination-item:hover{
  background:#7F56D9 !important;
}
.general-table .ant-pagination .ant-pagination-item:hover a{
  color:#fff !important;
}
.general-table .ant-pagination .ant-pagination-prev button, .general-table .ant-pagination .ant-pagination-next button{
  width:30px !important;
  height:30px !important;
  border:1px solid #D0D5DD !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin:0 5px !important;
  color:#7F56D9 !important;
  transition:0.5s ease-in-out !important;
  background: transparent !important;
}
.general-table .ant-pagination .ant-pagination-prev:hover button,.general-table .ant-pagination .ant-pagination-next:hover button{
  color:#fff !important;
  background: #7F56D9  !important;
}
.user-company-table .ant-table table thead tr th div span{
  color:#1D2939 !important;
  font-weight:500
}
.user-company-table .ant-table table tr td small{
  color:#1D2939 !important;
}
.user-company-table .ant-table table tr td small.company-name{
  color:#7F56D9 !important;
}
.company-package-table .ant-table table thead tr th div span{
  color:#1D2939 !important;
  font-weight:500
}
.company-package-table  .ant-table table tr td small{
  color:#1D2939 !important;
}
.assign-package-table .ant-table table tr th, .assign-package-table .ant-table table tr th div span{
  color:#1D2939 !important;
  font-weight:500
}
.assign-package-table  .ant-table table tr td div{
  color:#1D2939 !important;
}
.assign-package-table  .ant-table table tr td div.assign-text{
  color:#7F56D9 !important;
}
.default-property-table .ant-table table tr th, .default-property-table .ant-table table tr th div span{
  color:#1D2939 !important;
  font-weight:500
}
.default-property-table .ant-table table tr td div{
  color:#1D2939 !important;
}
.default-property-table  .ant-table table tr td div.assign-text{
  color:#7F56D9 !important;
}
.coaching-company-table .ant-table table tr th{
  color:#1D2939 !important;
  font-weight:500
}
.coaching-company-table .ant-table table tr td{
  color:#1D2939 !important;
}
.input-num-icon svg{
  margin-top:6px !important;
  margin-right:2px !important;
}
.ant-modal-footer{display:flex; align-items:center; justify-content:center}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar{
  height:2px !important
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar{
  height:2px !important
}
.ant-popover-title{
  padding: 10px 17px !important;
    font-size: 16px;
}
.ant-descriptions-item-label a, .ant-descriptions-item-content a{
  color:rgb(71, 84, 103)
}
.ant-descriptions-item-label a:hover, .ant-descriptions-item-content a:hover{
  color:#7F56D9
}
.general-table tbody tr td span{
  font-size: 15px;
  
}
.adminCompanyTable thead tr th , .adminCompanyTable thead tr th .ant-table-column-title,.adminCompanyTable tbody tr td ,
.user-company-table thead tr th .ant-table-column-title, .user-company-table thead tr th,.user-company-table tbody tr td,.user-company-table tbody tr td span,
.course-list-admin thead tr th, .course-list-admin thead tr th .ant-table-column-title, .course-list-admin tbody tr td, .course-list-admin tbody tr td span{
  font-size: 15px;
  
}
.adminCompanyTable tbody tr td small{
  font-size: 100%;
}
.budget_analysis_table{width:100%}
 /* .budget_analysis_table th, .budget_analysis_table td{
  border: 1px solid #D0D5DD;
  border-collapse: collapse;
} */
.budget_analysis_table th{
  border-bottom:1px solid #D0D5DD;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding:8px;
  text-align: right;
}
.budget_analysis_table td{
  color:#667085;
  font-size: 16px;
  font-weight: 500;
  padding:8px;
  text-align: right;
  border-bottom:1px solid #D0D5DD;
}
.budget_analysis_table td:first-child{
  text-align: left;
}

.campaign_select_cust .ant-select-selection-item{
  font-size: 30px;
  line-height: 38px;
  color: #1D2939;
  font-weight: 700;
}
.campaign_select_cust .ant-select-selector{border:none !important; padding:0 5px !important}
.campaign_select_cust .ant-select-arrow svg{
  font-size: 18px;
}
.campaign_select_cust.ant-select-focused .ant-select-selector{border:none !important; padding:0 5px !important}
.year_select_cust label.ant-radio-button-wrapper{
  background: #F9F5FF;
    padding: 5px 10px;
    border-radius: 17px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #6941C6;
    border:none;
    font-size:14px;
    margin-right:5px;
    margin-bottom:5px
}
.year_select_cust label.ant-radio-button-wrapper span{
  font-weight: 700;
}
.year_select_cust label.ant-radio-button-wrapper::before{
  width:0
}
.year_select_cust label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked, .year_select_cust label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover{
  background: #7F56D9;
  color:#fff
}
.new_budget .budget_link span{
  font-weight: 500;
}
.budget_remaining_chart canvas, .budget_planning_chart canvas{
  height:300px !important
}
.connect-tab span.budget{
  font-size: 16px;
  font-weight:700;
  border:1px solid rgba(208, 213, 221, 1);
  
  padding:8px 20px;
  display: inline-block;
  height:auto;
  border-radius: 40px;
  margin:0 2px;
  color:#344054;
}
.connect-tab span.budget.custom-selected-tab-selected{
  border:4px solid #F3EAFE

}
.campaign_search .campaign-search{
  border:1px solid #D0D5DD !important;
}
.campaign_search input::placeholder{
  color:#98A2B3;
}
.new_design_table .ant-table-tbody > tr > td{
  border-bottom: 1px solid #D0D5DD;
  padding:10px 10px
}
.new_design_table .ant-table-thead > tr > th{
  background:transparent !important;
  padding:16px 12px;
  border-bottom: 1px solid #D0D5DD;
}
.new_design_table .ant-table-thead > tr > th::before{
  background-color:transparent !important;
}
.new_design_table .ant-table-tbody > tr > td:nth-child(even),.new_design_table .ant-table-thead > tr > th:nth-child(even) {
  background: #f8fafb !important; 
}
.new_design_table .ant-table-tbody > tr:nth-last-child(-n+2) td{
  background: #FCFAFF !important;
}
.new_design_table_input.ant-input-number{
  background-color:transparent;
  margin-right:5px
}
.new_design_table_input.ant-input-number.ant-input-number-focused{
  outline:none;
  box-shadow: none;
  
}
.new_design_table_input.ant-input-number input{
  font-weight: 500;
  
}
label.ant-checkbox-wrapper.zero_checked{
  display:flex;
  align-items:center
}
label.zero_checked .ant-checkbox + span{
  margin-top:5px;
  padding-left:2px;
}
label.zero_checked .ant-checkbox .ant-checkbox-inner{
  width:24px !important;
  height:24px !important;
  border: 1px solid #7F56D9;
  background-color: #F3EAFE;
}
label.zero_checked .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner{
  background-color: #7F56D9;
}
label.zero_checked .ant-checkbox .ant-checkbox-inner::after{
  transform: rotate(45deg) scale(1.6) translate(-20%, -40%);
}
label.zero_checked .ant-checkbox:hover .ant-checkbox-inner{
  border-color:#7F56D9;
}

label.zero_checked .ant-checkbox.ant-checkbox-checked:hover::after{
  border: 1px solid transparent;
}
label.ant-checkbox-wrapper.zero_checked:hover .ant-checkbox-checked::after{
  border: 1px solid transparent;
}
.ant-modal-root .ant-modal-mask{
  background-color: rgba(255, 255, 255, 0.22);  
  backdrop-filter: blur(8px);
}
.budget_Planned_modal .ant-modal-body{
  padding:0
}
.budget_Planned_modal .ant-modal-content{padding:22px 18px}
.ant-drawer-mask{
  background-color: rgba(255, 255, 255, 0.2) !important;
  backdrop-filter: blur(7px);
}
.cust_drawer .ant-drawer-header{
  background: transparent;
  border-bottom:none
}
.cust_drawer .ant-drawer-header-title button{
    width: 32px;
    height: 32px;
    border: 1px solid #D0D5DD;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.drawer_label{
  color: #344054;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
}
.ant-input-number.drawer_input{
  width:220px !important
}
.checkBox.editCampaign span.ant-checkbox{
  margin-top:0
}
.budget_select_year_loader{
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(255,255,255,0.7);
  z-index: 99;
  backdrop-filter: blur(3px);
  display:flex;
  align-items:center;
  justify-content: center;
}
input[type="checkbox"].filter_input{
  width:24px;
  height:24px
}
table.custom_description_item_table{
  width:100%;
}
table.custom_description_item_table,
table.custom_description_item_table th,
table.custom_description_item_table td{
  border:1px solid #D0D5DD;
  text-align: left;
  padding:15px 10px;
  color:#1D2939;
  
}
table.custom_description_item_table td a{
  color:#1D2939;
  transition: all 0.5s ease-in-out !important;
}
table.custom_description_item_table td a:hover{
  color:#6941C6
}
table.custom_description_item_table th{
  background:#F3EAFE;
  font-weight:500
}

.revenue_forecasting_table .ant-table-container table > thead > tr:first-child th:first-child{
 
  display:flex;
  align-items: center;
  width:182px;
  border-bottom:1px solid #D0D5DD;
  height:49.22px;
  
}
.revenue_forecasting_table .ant-table-container table{padding-bottom:'10px'}
.revenue_forecasting_table .ant-table-container table > thead > tr:first-child th:first-child::before{
  display: none;
}
.revenue_forecasting_table .ant-table-container table > thead > tr th{
  color:#475467;
  font-weight:400;
  font-size: 14px;
  background: #fff !important;
  width: 110px;
  text-align: center;
  border-bottom:1px solid transparent;
  
}
.revenue_forecasting_table .ant-table-container table > thead > tr th::before{
  position: absolute !important;
  top: 100% !important;
  right: 0 !important;
  width: 92% !important;
  height: 1px !important;
  background-color: #D0D5DD !important;
  transform: translateY(-50%) !important;
  transition: background-color 0.3s !important;
  content: '';
  left:0 !important;
  margin:0 auto;

}
.revenue_forecasting_table .ant-table-tbody > tr > td{
  font-size: 14px;
  padding:10px 0;
  border-bottom:none;
  
}
.revenue_forecasting_table .ant-table-tbody > tr > td:first-child a{
  color:#475467;
  font-weight:500
}
.revenue_forecasting_table .ant-table-tbody > tr > td:first-child{
  border-bottom:1px solid #D0D5DD;
  padding:16px 16px;
  
}
.revenue_forecasting_table .ant-table-tbody > tr:last-child > td:first-child{
  border-bottom:none
}
.revenue_forecasting_table .table_cell_div{
  display:flex;
  align-items:center;
  justify-content: center;
  width:100px;
}
.forecasting_details .indications .item{
  display:flex;
  align-items: center;
  margin-left:10px
}
.forecasting_details .indications .item span.name{
  font-size: 12px;
  color:#475467;
  font-weight:500;
  display:inline-block
}
.forecasting_details .indications .item span.color{
  width:17px;
  height:17px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  display:inline-block;
  margin-right:4px
}
/***** forecast popover *****/
.ant-popover-title{
  background: #7F56D9;
  padding: 12px 20px !important;
  border-bottom: 1px solid #7F56D9 !important;
  color:#fff !important;
}
.popover_item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom:1px solid #475467;
  padding-bottom:10px;
  margin-bottom:10px;
}
.popover_item:last-child{
  border-bottom:none;
  padding-bottom:0;
  margin-bottom:0;
}
.popover_item .name{
  font-size: 12px;
  color:#475467;
  line-height: 16px;
  font-weight: 500;
  margin-left:10px;
  min-width:230px
}
.popover_item .amount{
  font-size: 12px;
  color:#475467;
  line-height: 16px;
  font-weight: 500;
  margin-left:10px;
}
.popover_item .color{
  width:17px;
  height:17px;
  border-radius: 50%;
  display:inline-block;
}
.popover_item .left{
  display:flex;
  align-items: center;
}
.popover_wrapper::-webkit-scrollbar {
  width: 10px;
  display: block !important;
}
.ant-popover-inner-content{
  padding:0 !important;
}
.popover_wrapper:hover{
  overflow: auto !important;
}

/* Track */
.popover_wrapper::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.popover_wrapper::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.popover_wrapper::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.forecasting_content_main{position:relative; height:100%}
/* .forecasting_chart_overlay{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:100% ;
  background:#fff;
  z-index:1030;
  border:1px solid #D0D5DD;
  visibility: hidden;
  -moz-transition: all 0.8s ease-in-out !important;
  -webkit-transition: all 0.8s ease-in-out !important;
  transition: all 0.8s ease-in-out !important;
  height: 100%;
  overflow: hidden;
  padding:20px 0;
  
}
.forecasting_chart_overlay.open{
  left: 0;
  visibility:visible;
}
.forecasting_chart_overlay.close{
  left: 100%;
  visibility:hidden;
  
} */
.forecasting_chart_overlay{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0 ;
  background:#fff;
  z-index:1030;
  border:1px solid #D0D5DD;
  visibility: hidden;
  -moz-transition: all 0.8s ease-in-out !important;
  -webkit-transition: all 0.8s ease-in-out !important;
  transition: all 0.8s ease-in-out !important;
  height: 100%;
  width:100%;
  padding:20px 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  overflow-y:auto;
  overflow-x:hidden
}

/* .forecasting_chart_overlay:hover{
  overflow:auto;
} */
.forecasting_chart_overlay.open{
  opacity:  1;
  visibility:visible;
  pointer-events: all;
}
.forecasting_chart_overlay.close{
  opacity:  0;
  visibility:hidden;
  pointer-events: none;
  
}

button.overlay_back{
  padding: 0px 20px;
  position: absolute;
  left: -155px;
  top:50%;
  transform:translateY(-50%) rotate(90deg);
  width: 350px;
  z-index: 1030;
}
.back_overlay{
  height: 35px;
  width: 35px;
  font-size: 20px;
  background: #7f56d9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-right:20px;
  cursor: pointer;
}
.forecasting_chart_overlay.close{
  filter:blur(5px);
}
.forecasting_chart_overlay.open{
  filter:blur(0);
}
.forecasting_chart_overlay.close .forecasting_details.charts{
  visibility: hidden;
  opacity: 0;
  transition:all 0.5s ease-in-out;
}
.forecasting_chart_overlay.open .forecasting_details.charts{
  transition:all 0.5s ease-in-out;
  visibility: visible;
  opacity: 1;
}
.forecasting_table_wrapper{
  overflow-y: hidden;
  overflow-x: hidden;
}
.forecasting_table_wrapper:hover{
  overflow-x: auto;
}
.forecasting_chart_overlay::-webkit-scrollbar,.forecasting_table_wrapper::-webkit-scrollbar {
  width: 8px !important;
  display: block !important;
  
}
/* Handle */
.forecasting_chart_overlay::-webkit-scrollbar-thumb,
.forecasting_table_wrapper::-webkit-scrollbar-thumb {
  background: rgba(71, 84, 103, 0.6)!important; /* color of the scrollbar handle */
  border-radius: 6px !important; /* roundness of the scrollbar handle */
}

/* Handle on hover */
.forecasting_chart_overlay::-webkit-scrollbar-thumb:hover,
.forecasting_table_wrapper::-webkit-scrollbar-thumb:hover {
  background: rgba(141, 102, 227, 0.2) !important; /* color of the scrollbar handle on hover */
}

/* Track */
.forecasting_chart_overlay::-webkit-scrollbar-track,
.forecasting_table_wrapper::-webkit-scrollbar-track {
  background: #f1f1f1 !important; /* color of the scrollbar track */
}

/* Track on hover */
.forecasting_chart_overlay::-webkit-scrollbar-track:hover,
.forecasting_table_wrapper::-webkit-scrollbar-track:hover {
  background: #ccc !important; /* color of the scrollbar track on hover */
}

/* .forecasting_table_wrapper */