#customStagesTable table {
    border-top: none;
    border-spacing: 16px 0;
    margin-left: -16px;
}

#customStagesTable thead th {
    border: none;
}

#customStagesTable thead th:nth-child(1){
    background: #E9F2FF !important;
}

#customStagesTable thead th:nth-child(3),
#customStagesTable thead th:nth-child(4),
#customStagesTable thead th:nth-child(5) {
    background: #030D1D !important;
    color: #fff !important;
}

#customStagesTable thead th:nth-child(6){
    border-radius: 7px 0 0 0 !important;
    background: #E9F2FF !important;
}


#customStagesTable td:nth-child(2) {
    border-right: none;
}

#customStagesTable td:not(:nth-child(2)) {
    border-left: 1px solid #f0f0f0;
}

#customStagesTable .ant-tag {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px !important;
}

#customStagesTable th:last-child {
    background: #fff !important;
    border-radius: 0 !important;
}

#customStagesTable td:last-child {
    border: none !important;
}

#customStagesTable th:nth-last-child(2)::after {
    border-right: none !important;
}

#customStagesTable .italic-cell span {
    word-break: break-word;
}

#customStagesTable .italic-cell span.three-text-rows {
    /* max-height: 60px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3;
    -webkit-box-orient: vertical;

}

#customStagesTable .ant-select-selector  , .Create-mapping-stage .ant-select-selector{
    overflow: scroll;
}
#customStagesTable .stages-table.h-50 .ant-select-selector{
    height: 46px !important;
    max-height: 46px !important;
}
#customStagesTable .stages-table.h-80 .ant-select-selector{
    height: 80px !important;
    max-height: 80px !important;
}
.salesMarketing .ant-select-selector {
    overflow: scroll;
}

#customStagesTable .ant-select-selector .ant-tag-close-icon{
    margin-left: 8px !important;
    font-size: 12px !important;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
#customStagesTable .ant-select-selector .ant-tag-close-icon svg{
    color: #A3C8FF;
}

.site-drawer-render-in-current-wrapper1 {
    position: relative;
    width: 100%;
    height: fit-content !important;
    /* padding: 48px; */
    overflow: auto !important;
    text-align: center;
    /* background: #fafafa; */
    /* border: 1px solid #ebedf0; */
    /* border-radius: 2px; */
}
.site-drawer-render-in-current-wrapper1 .ant-drawer-body{
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.site-drawer-render-in-current-wrapper1 .drawer.h-80 .ant-drawer-body{
    height: 80px !important;
}
#customStagesTable  .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper{
    box-shadow: none !important;
}
td.crm-stages-cell.h-90{
    height: 90px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Create-mapping-stage .ant-form-item-label label{
    font-weight: 600;
}
.Create-mapping-stage .ant-drawer-header-title{
    flex-direction: row-reverse;
}
.mapping-drawer .ant-select-selector {
    height: auto;
    max-height: 75px !important;
}
.custom-option:hover {
    background-color: rgb(230, 242, 255) !important;
}