@import "ThemeVariables.less";
// Color variables (appears count calculates by raw css)
@color0: #808080;
@color2: #727272;
@color3: #303030;

// Width variables (appears count calculates by raw css)
@width0: 100%; // Appears 2 times

.budget-select {
  .ant-select-single {
    width: @width0 !important;
  }
}

.delete-budget-camp .ant-modal-footer{
  padding: 0px;
  width: 0px;
}
.summary-right-section {
  color: @color0;
  font-size: 12px;
  margin-top: 7px;
  text-align: left;
  width: @width0;
  border-bottom: 1px solid #e5e5e5;
  padding: 5px;
}
.budget-card-number {
  color: @themecolor4;
  text-align: center;

  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.budget-card-number-remaining {
  color: @themecolor1;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}
.budget-card-number-planned {
  color: @themecolor3;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  vertical-align: middle;
}
.campaign-table-fy-totals {
  color: @themecolor1;
  font-weight: bold;
  float: unset;
  text-align: center;
  padding-left: 0px;
}
.campaign-table-win-deals{
  color: #7B19BF;
  font-weight: bold;
}
.budget-card-number-achieved {
  color: @themecolor1;

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  vertical-align: middle;
}
.budget-card-text {
  color: @color3;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}
.budget-card-text-analysis {
  color: @color2;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 8px;
}
.budget-column {
  justify-content: center;
  padding: "15px 0";
}
.budget-help-text {
  color: @themecolor6;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.font-color-budget {
  color: @color0;
}
.font-budget-header {
  color: @color0;
}
.budget-active-status {
  color: @bodyFontColor;
  font-size: 20px;
  font-weight: bold;
  height: 100%;
}
.budget-row {
  border-radius: 5px;
  border-bottom: 1px solid #D0D5DD;
}
.budget-cell {
  
  display: flex;
  align-items: center;
}
.channel-name-font-style {
  font-weight: bold;
  color: @themecolor4;
}
.create-campaign-section-header {
  font-weight: bold;
  margin-bottom: 15px;
}
.advance_settings {
  background: none;
  .ant-select-selector {
    border: 1px solid #dedede !important;
    border-radius: 7px !important;
    height: auto !important;
    padding: 5px;
  }
}

.budget-amount-green {
  color: #a9a9a9;
}

.primary-color {
  color: @bodyFontColor;
}
.primary-color-background {
  background: @bodyFontColor !important;
}

th ~ .selected-column {
  background-color: @bodyFontColor !important;
}

.red-font {
  color: #cb4154;
}
@page {
  size: A4;
}
@media print {

  .non-print {
    display: none;
  }
  .only-print.nextPage{
    margin-top: 250px;
  }
  .only-print .row{
    display: flex;
    justify-content: center !important;
    align-items: center;
  }
  .only-print{
    display: block !important;
  }
  .campaign-planning-section{
    width: 100% !important;
  }
  .budget-planning-table-header .tag1{
    margin-left: 55px;
  }
  .budget-planning-table-header .tag2{
    margin-left: 35px;
  }
  .print-box .font-color-budget {
    display: flex !important;
    flex-direction: column !important;
  }
  .print-grid{
    display: grid;
    grid-template-columns: 70% 40%;
    position: relative;
  }
  .print-grid .w-100{
    width: 100% !important;
    position: absolute !important;
    right: 0 !important;
    top: 70px!important;
  }
  .print-grid .w-101{
    width: 100% !important;
    position: absolute !important;
    right: 0 !important;
    top: 50px!important;
  }
}

.disable-color {
  color: @color0;
}

.enable-color {
  color: @bodyFontColor;
  font-weight: bold;
}

.float-child .ant-select-selector {
  height: 37px !important;
  padding: 3px 10px !important;
  border-radius: 7px !important;
}

.search {
  float: left;
  width: 54px;
  height: 44px;
  background: @themecolor2;
  color: white;
  font-size: 17px;
  border: none;
  cursor: pointer;
  border-radius: 7px;
}

.search-disable {
  background: #d9d9d9;
}

.search_img {
  height: 22px;
  margin-bottom: 3px;
}

.create-budget-btn {
  height: 44px;
  width: 100%;
}
.create-btn-type-2 {
  height: 44px;
  width: 100%;
  background: #ffffff;
  border-radius: 7px;
  border: 1px solid @themecolor1;
  color: @themecolor1;
  font-weight: bold;
}
.search:hover {
  background: @themecolor2;
}
.search:focus {
  background: @themecolor2;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: @themecolor1;
  border-color: @themecolor1;
}

.campaign-tabs {
  background-color: #fff;
  border-radius: 7px;
}

.empty-budget-profile-view {
  background-color: #fbfbfb;
  border-radius: 5px;
  border: 1px solid rgb(235, 237, 240);
}

.budget-tab .ant-tabs-nav-wrap {
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid #F1F1F1;
  padding-left: 20px;
  // margin-right: 5px;
}

.campaign-planning-section {
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid #fff;
  padding:15px 15px;
  margin-top: 15px;
 
  width: 100%
}
.campaign-planning-section-sub {
  padding-left: 10px;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: @themecolor2;
  pointer-events: none;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 3.5px;
}

.no-budget-profile-img {
  text-align: center;
  margin-top: 74px;
}
.no-budget-profile-content-parent {
  width: 58%;
  margin-left: 21%;
}
.no-budget-profile-content-child1 {
  margin-top: 30px;
  color: @color3;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}
.no-budget-profile-content-child2 {
  margin-top: 10px;
  color: #727272;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: center;

}
.no-budget-profile-content-child3 {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 74px;
}

.card-select-budget {
  min-height: 120px;
  padding: 10px 10px 0 10px;
  border-radius: 7px;
}

.ant-tabs-extra-content {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
  padding-left: 10px;
}

.total-budget-content {
  background: @themecolor4;
  border-radius: 7px;
  color: #ffffff;
  height: 45px;
  text-align: center;
  padding-top: 10px;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 5px 0;
}

.quarter-filter {
  color: #050505;
  text-align: center;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  display: flex;
  justify-content: space-between;
}

.analisys-text {
  padding-left: 12px;
  color: @themecolor3;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.total-spend-text {
  color: var(--secondaryColor);
  font-weight:700;
}
.total-leads-text {
  color: @color3;
}

.revenue-text {
  padding-left: 12px;
  color: @themecolor1;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.font-color-budget .ant-input-affix-wrapper {
  border: 1px solid rgb(196, 196, 196);
  border-radius: 7px;
}

.campaign-planning-title {
  padding-left: 10px;
  color: @themecolor5;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  vertical-align: middle;
}
.campaign-planning-title-spend {
  padding-left: 10px;
  color: @themecolor5;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  vertical-align: middle;
  font-size: 18px;
  line-height: 23px;
}


.custom-selected-tab {
  
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border-radius: 7px;
  cursor: pointer;
  padding-top: 3px;
  font-size: 16px;
  
}
.custom-selected-tab-selected {
  background: #fff;
    display: inline-flex;
    text-align: center;
    vertical-align: middle;
    border-radius: 7px;
    cursor: pointer;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;
    /* padding: 10px 18px; */
    
    
    align-items: center;
    /* background-blend-mode: hue; */
    justify-content: center;
}

.campaign-planning-section .ant-table-container {
  border: none !important;
}

.table-header-img {
  cursor: pointer;
  padding-left: 5px;
}

.table-header-text {
  vertical-align: middle;
}

.table-header-img-icon {
  padding-bottom: 3px;
}

.cursor-pointer {
  cursor: pointer;
}

.campaign-table .ant-table-cell {
  display: table-cell;
}

.campaign-name-font-style {
  color: #3f3f3f !important;
}

.ant-table-row-indent {
  padding-left: 0px !important;
}
.campaign-table{
td:last-child {
  border-right: none !important;
}

th:nth-child(n + 2),
td:nth-child(n + 2) {
  text-align: center !important;
}

.campaign-total-amount-diff {
  color: #a9a9a9;
  font-size: 12px;
}

tr:first-child {
  border-radius: 7px;
}
}

.empty-state td {
  text-align: left !important;
}

.create-new {
  color: @themecolor1;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: right;

}

.create-new-text {
  color: #a9a9a9;
  padding-left: 5px;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;

}


.create-campaign a,
.ant-form a {
  color: @themecolor1;
  text-decoration: underline;
}
.campaign-planning-section a {
  color: @themecolor1;
  text-decoration: underline;
}

.ant-select-selector {
  border: 1px solid #dedede !important;
  border-radius: 7px !important;
  // height: 44px !important;
}
.ant-input {
  border: 1px solid #dedede !important;
  border-radius: 7px !important;
}
.ant-input-affix-wrapper .ant-input {
  border: none !important;
  border-radius: unset !important;
}
.ant-input-affix-wrapper {
  border: 1px solid #dedede !important;
  border-radius: 7px !important;
}

.ant-form-item-extra {
  padding-left: 3px;
}

.ant-picker {
  border: 1px solid #dedede !important;
  border-radius: 7px !important;
}

.budget-planning-section {
  border: 1px solid #dedede !important;
  border-radius: 7px !important;
}

.ant-btn-secondary,
.ant-btn-secondary:hover,
.ant-btn-secondary:focus,
.ant-btn-secondary:active {
  border: 1px solid #0f61db;
  box-sizing: border-box;
  border-radius: 7px;
  color: #0f61db;
  font-weight: bold;
}


.budget-planning-reset {
  float: right;
  margin-right: 15px;
  color: #727272;
  cursor: pointer;
}

.budget-planning-allocate-cell {
  display: inline-block;
 
}

// tr:first-child th:first-child {
//   border-top-left-radius: 7px !important;
// }
// tr:first-child th:last-child {
//   border-top-right-radius: 7px !important;
// }
// tr:last-child th:first-child {
//   border-bottom-left-radius: 7px !important;
// }
// tr:last-child th:last-child {
//   border-bottom-right-radius: 7px !important;
// }

.campaign-search .ant-input {
  height: 33px !important;
}
.campaign-search-heighlight .ant-input{
  background-color: yellow;
  outline: none;
}
.campaign-search-heighlight.ant-input-affix-wrapper-focused{
  box-shadow: none;
}

.campaign-search .ant-input:placeholder-shown{
  font-size: 18px;
}

.ant-statistic-content {
  color: #a9a9a9;
  font-size: 12px;
}

.ant-statistic-content-prefix {
  margin-right: 1px;
}

.ant-statistic-content-suffix {
  margin-left: 1px;
  font-size: 12px;
}

.under-budget .ant-statistic-content {
  color: #667085;
}

.over-budget .ant-statistic-content {
  color: #667085;
}

.under-budget {
  color: #00cd83;
  font-size: 12px;
}
.over-budget {
  color: #ea4b4b;
  font-size: 12px;
}

.channel-text {
  font-weight: bold;
  color: @themecolor4;
}

.save-budget-planning {
  font-weight: 500;
  float: right;
  margin-right: 15px;
  color: @themecolor1;
  cursor: pointer;
}

.budget-campaign-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: @themecolor5;
}

.budget-year .ant-select-selection-item {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.ant-drawer-content-wrapper .ant-select-selection-item{
  // line-height: 2.5 !important;
  font-size: 18px;
}

.budget-create-label {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: @themecolor5;
}

.create-budget-btn {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
}

.total-budget-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: right;
  padding-right: 5px;
  vertical-align: middle;
}

.total-budget-amount {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: right;
  vertical-align: middle;
}

.budget-tab .ant-tabs-tab-btn {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #727272;
}

.print-icon {
  float: right;
  padding: 5px;
  background: @themebackground;
  height: 38px;
  width: 38px;
  left: 1348px;
  top: 690px;
  border-radius: 7px;
}

.ant-table table {
  font-size: 18px;
}

.budget-planning-sub-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  color: @themecolor6;
}

.ant-drawer-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #1D2939;
}
.ant-form-item-label > label {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #1D2939
}

.ant-form-item-extra {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color:"#98A2B3"
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1D2939;
  font-weight: 600;
}
.delete-modal-body{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}
.delete-modal-body img{
  height: 55px;
}
.delete-modal-footer{
  margin-top: 10px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.suspend-company-footer .ant-modal-footer{
  margin: 0;
  padding: 0;
  width: 0;
}
.delete-modal-body button{
  margin: 0px 10px;
  width: 50%;
}

.only-print{
  display: none;
}
.campaign-table-print{
  padding-top: 30px;
  min-height: 95vh;
  max-height: max-content;
}
.select-BaselineMetricID{
  background: none;
  .ant-select-selector {
    border: 1px solid #dedede !important;
    border-radius: 7px !important;
    height: auto !important;
    padding: 5px;
  }
}

.source-selection-multiple .ant-select-selector {
  height: auto !important;
}
.ant-select-arrow{
  color: #000;
}
.ant-picker-header-super-prev-btn,.ant-picker-header-super-next-btn {
    display: none !important;
  }

  .budget-table-actual-data{
    color: #7B19BF !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .budget-table-actual-data img{
    margin-left: 5px;
  }

.ant-tooltip-placement-bottomLeft {

  .ant-tooltip-inner {
    color: black !important;
    background-color: white !important;
    border-radius: 5px !important;
  }

  .ant-tooltip-placement-bottom .ant-tooltip-arrow, .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow, .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
      border-bottom-color: white !important;
  }

}

.map-campaign-form .ant-select-selection-item{
  margin: auto;
}

.campaign-name-font-style input {
  border: 1px solid #D0D5DD;
  background-color: #fff;
  outline: none !important;
  text-align: center;
}
.campaign-planning-section {
textarea.ant-input::-webkit-scrollbar{
  width: 0px !important;
}
textarea.ant-input{
  border: none !important;
  resize: none !important;
  background-color: #fff !important;
  overflow: hidden;
  width: 90%;
  padding: 5px !important;
}
}
.campaign-table-save-reset-icon{
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 3px;
  padding: 0;
  top: 50%;
  transform: translate(0%, -50%);

}

.custom-date-picker{
  border-radius: 15px !important;
  padding: 4px 12px !important;
  background-color: white !important;
  height: 44px !important;
  border-radius: 7px !important;
  border: 1px solid #d9d9d9 !important;
  width: 100% !important;
  text-overflow: ellipsis !important;
  overflow-x: scroll !important;
}

.custom-date-picker:focus{
  outline: none !important;

}
.rmdp-day.rmdp-selected span:not(.highlight){
  background-color: #0F61DB !important;
  color: #fff !important;
}
.rmdp-today span:hover{
  background-color: #7ea6f0ed !important;
  color: #fff !important;
}
.rmdp-ym .rmdp-day span {
  border-radius: 3px !important;
}
.rmdp-day.rmdp-today span{
  background-color: #fff ;
  color: black ;
}

.edit-prevent-section{
position: relative;
border: #dedede 1px solid;
border-radius: 7px;
}
.edit-prevent-img{
  position: relative;
  height: 100%;
  width: 100%;
  cursor: not-allowed;
}

.edit-prevent-text{
  top: 0;
    z-index: 1;
  text-align: left;
  padding:15px;
}

.edit-prevent-disabled{
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(177, 177, 177, 0.1);
  z-index: 1;
}

.custom-date-picker::placeholder{
  color: #cbc4c4 !important;
  font-size: 18px !important;
}

.checkBox .ant-checkbox{
  margin-top: 7px;
}
.ant-input-number-handler-wrap{
  display: none !important;
}
.date-picker-heading{
  font-weight: bold;
  position: fixed;
  padding: 10px;
  top: 0;
  width: 100%;
  text-align: center;
}
.only.rmdp-month-picker, .only.rmdp-year-picker {
  padding-top: 22px !important;
}

#file-upload-button{
  background-color: #00cd83 !important;
}
.ant-upload-list-item-name{
  white-space: normal !important;
}
.select-value {
  .ant-select-selection-item{
    margin: auto !important;
  }

}
.collaspe-budget {
  .ant-collapse-header{
    background-color: #F3F3F3 !important;
    font-size: 16px;
    font-weight: 600;
  }
  .ant-collapse-item:nth-child(2), .ant-collapse-item:nth-child(3), .ant-collapse-item:nth-child(4), .ant-collapse-item:nth-child(5), .ant-collapse-item:nth-child(6){
    margin-top: 15px;
  }
}
.italic-style{
  color:#0F61DB;
  font-style: italic;
}
.delete-warning{
  display: flex;
  text-align: center;
  font-style: italic;
  color: #0F61DB
}
.campaign-table th.ant-table-cell-fix-right-first{
  right:0 !important
}
@primary-color: #0f61db;@menu-dark-bg: #030D1D;@menu-light-bg: #e0e0e0;@layout-sider-background: #e0e0e0;@menu-dark-item-active-bg: #030D1D;@menu-highlight-color: #1CD9F2;@menu-dark-selected-item-text-color: #1CD9F2;@menu-dark-selected-item-icon-color: #1CD9F2;@menu-dark-item-hover-bg: #030D1D;@menu-light-item-hover-bg: #e0e0e0;@font-family: Gilmer;